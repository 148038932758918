// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.btn-cancelar {
  background: none;
  border: none;
  margin-right: 20px;
  padding: 10px 20px; /* Ajusta el padding según lo necesites */
  border-radius: 8px; /* Borde redondeado */
}

.btn-cancelar:hover {
  background-color: #f0f0f0; /* Gris más claro */
}

.btn-confirmar {
  background: none;
  border: none;
  margin-right: 4% !important;
  padding: 10px 20px; /* Ajusta el padding según lo necesites */
  border-radius: 8px; /* Borde redondeado */
}

.btn-confirmar:hover {
  background-color: #f0f0f0; /* Gris más claro */
}`, "",{"version":3,"sources":["webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/points/components/update-expiry-date-points/update-expiry-date-points.component.scss","webpack://./src/app/pages/points/components/update-expiry-date-points/update-expiry-date-points.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACAhB;EACI,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA,EAAA,yCAAA;EACA,kBAAA,EAAA,qBAAA;ADEJ;;ACCA;EACI,yBAAA,EAAA,mBAAA;ADEJ;;ACCA;EACI,gBAAA;EACA,YAAA;EACA,2BAAA;EACA,kBAAA,EAAA,yCAAA;EACA,kBAAA,EAAA,qBAAA;ADEJ;;ACCA;EACI,yBAAA,EAAA,mBAAA;ADEJ","sourcesContent":["@charset \"UTF-8\";\n.btn-cancelar {\n  background: none;\n  border: none;\n  margin-right: 20px;\n  padding: 10px 20px; /* Ajusta el padding según lo necesites */\n  border-radius: 8px; /* Borde redondeado */\n}\n\n.btn-cancelar:hover {\n  background-color: #f0f0f0; /* Gris más claro */\n}\n\n.btn-confirmar {\n  background: none;\n  border: none;\n  margin-right: 4% !important;\n  padding: 10px 20px; /* Ajusta el padding según lo necesites */\n  border-radius: 8px; /* Borde redondeado */\n}\n\n.btn-confirmar:hover {\n  background-color: #f0f0f0; /* Gris más claro */\n}",".btn-cancelar {\n    background: none;\n    border: none;\n    margin-right: 20px;\n    padding: 10px 20px; /* Ajusta el padding según lo necesites */\n    border-radius: 8px; /* Borde redondeado */\n}\n\n.btn-cancelar:hover {\n    background-color: #f0f0f0; /* Gris más claro */\n}\n\n.btn-confirmar {\n    background: none;\n    border: none;\n    margin-right: 4% !important;\n    padding: 10px 20px; /* Ajusta el padding según lo necesites */\n    border-radius: 8px; /* Borde redondeado */\n}\n\n.btn-confirmar:hover {\n    background-color: #f0f0f0; /* Gris más claro */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
