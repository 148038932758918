// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell-data {
  overflow-wrap: break-word;
  word-break: break-all;
}

.account-field .field-label {
  font-weight: bold;
}
.account-field .field-value {
  overflow-wrap: break-word;
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/app-users/app-users.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/app-users/app-users.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,qBAAA;ACCF;;ADGE;EACE,iBAAA;ACAJ;ADGE;EACE,yBAAA;EACA,qBAAA;ACDJ","sourcesContent":[".cell-data {\n  overflow-wrap: break-word;\n  word-break: break-all;\n}\n\n.account-field {\n  .field-label {\n    font-weight: bold;\n  }\n\n  .field-value {\n    overflow-wrap: break-word;\n    word-break: break-all;\n  }\n}\n",".cell-data {\n  overflow-wrap: break-word;\n  word-break: break-all;\n}\n\n.account-field .field-label {\n  font-weight: bold;\n}\n.account-field .field-value {\n  overflow-wrap: break-word;\n  word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
