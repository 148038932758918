// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  width: 100%;
  margin: 0 auto;
}

.search-container {
  margin-bottom: 3%;
}

.brand-title {
  display: flex;
  justify-content: space-between;
}
.brand-title span {
  max-width: 70%;
}

.brands-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.brand-container {
  margin-bottom: 3%;
}

.btn-edit {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/brands-list/brands-list.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/pointz/brands-list/brands-list.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;ACCF;;ADCA;EACE,iBAAA;ACEF;;ADAA;EACE,aAAA;EACA,8BAAA;ACGF;ADFE;EACE,cAAA;ACIJ;;ADDA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;ACIF;;ADDA;EACE,iBAAA;ACIF;;ADFA;EACE,eAAA;ACKF","sourcesContent":[".loader {\n  width: 100%;\n  margin: 0 auto;\n}\n.search-container {\n  margin-bottom: 3%;\n}\n.brand-title {\n  display: flex;\n  justify-content: space-between;\n  span {\n    max-width: 70%;\n  }\n}\n.brands-container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.brand-container {\n  margin-bottom: 3%;\n}\n.btn-edit {\n  cursor: pointer;\n}\n",".loader {\n  width: 100%;\n  margin: 0 auto;\n}\n\n.search-container {\n  margin-bottom: 3%;\n}\n\n.brand-title {\n  display: flex;\n  justify-content: space-between;\n}\n.brand-title span {\n  max-width: 70%;\n}\n\n.brands-container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.brand-container {\n  margin-bottom: 3%;\n}\n\n.btn-edit {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
