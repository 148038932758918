export const environment = {
  production: false,
  apikey: 'AIzaSyA07ubkK91Rkqv_92Tb_6sWNvNf4twWmJQ',
  gluky: {
    app_id: 'gluky_admin',
  },
  gmApiKey: 'AIzaSyAhKdTC6kvQG5hfOGeKheKbsWBo1ZtkTXc',
  firebase: {
    users: {
      apiKey: 'AIzaSyDFEqcn4w6_WjCfmTkYaq25wgnFRazdQeo',
      authDomain: 'users-174020.firebaseapp.com',
      databaseURL: 'https://users-174020.firebaseio.com',
      projectId: 'users-174020',
      storageBucket: 'users-174020.appspot.com',
      messagingSenderId: '905998910964',
    },
    challenges: {
      apiKey: 'AIzaSyA2yUXFcDmavnn-ogr5FsFygwSoF29kLkY',
      authDomain: 'gluky-challenge-test.firebaseapp.com',
      projectId: 'gluky-challenge-test',
      storageBucket: 'gluky-challenge-test.appspot.com',
      messagingSenderId: '529646150929',
      appId: '1:529646150929:web:23d49e34acbe4ef82f98b0',
      measurementId: 'G-QLDRGBW92J'
    },
    dataimporters: {
      apiKey: 'AIzaSyDgIYdwpYQzQz0ZNafRnz3ize25qT-qq5o',
      authDomain: 'dataflow-test-261002.firebaseapp.com',
      projectId: 'dataflow-test-261002',
      storageBucket: 'dataflow-test-261002.appspot.com',
      messagingSenderId: '998649369724',
      appId: '1:998649369724:web:a0424f4319d74e785e65f9'
    }
  },
  usersApiBasePath: 'https://users-test-api.glukynet.com/v2',
  pointz_api: {
    uri_brands: 'https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1/allies/brands',
    uri_exchanges: 'https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1/exchange',
    uri_allies: 'https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1/allies/commerce',
    uri_sales_points: 'https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1/allies/sales-locations',
    uri_cloudinary: 'https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1/cloudinary',
    base_url_new: 'https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1',
  },
  points_api: {
    base_url: 'https://bank-test-api.glukynet.com/v1',
  },
  exhibition_api: {
    base_url: 'https://exhibition-admin-us-east1-6rbdn5ld.ue.gateway.dev/v1/apps',
  },
  orders_api: {
    base_url: 'https://orders-test-api.glukynet.com/v1',
  },
  recognitions_api: {
    apiKey: 'AIzaSyA07ubkK91Rkqv_92Tb_6sWNvNf4twWmJQ',
    base_url: 'https://recognitions-admin-us-east1-4zduh59r.ue.gateway.dev/v1',
  },
  bags_api: {
    apiKey: 'AIzaSyA07ubkK91Rkqv_92Tb_6sWNvNf4twWmJQ',
    base_url: 'https://bolsas-admin-mbsx99e.ue.gateway.dev/v1',
  },
  kpi_api: {
    apiKey: 'AIzaSyDeIU8BCLLWmZmsjUFuuew4U3BF_XjKwFQ',
    base_url: 'https://kpis-admin-us-east1-1aybtui3.ue.gateway.dev/v1',
  },
  cloudinary_config: {
    cloud_name: 'glukygroup',
    apiKey: '112786663666772',
  },
  public_id_cloudinary: '/Brands/Brands-test/',
  uploadPreset: 'Brands-test',
  widget_cloudinary_config: {
    cloudName: 'glukygroup',
    api_key: '112786663666772',
    language: 'es',
    showAdvancedOptions: true,
    uploadPreset: 'Brands-test',
    theme: 'minimal',
    showCompletedButton: false,
  },
  strapi_cms: {
    base_url: 'https://strapi.cms-test.glukynet.com/admin/auth/login'
  },
  drupal_cms: {
    base_url: 'https://gnxdmcheus7ln.devcloud.acquia-sites.com',
  },
  appDomain: 'admin-test.glukynet.com',
  redemption_rules_api:{
    apiKey: 'AIzaSyA07ubkK91Rkqv_92Tb_6sWNvNf4twWmJQ',
    base_url: 'https://redemption-rule-admin-us-east1-9lsi9imq.ue.gateway.dev/v1',
  },

  reports:{
    invoiceUrl:"https://lookerstudio.google.com/embed/reporting/1426819d-26d8-4613-8fa5-5873de6007c0/page/T1k6C",
    exhibitionUrl:"https://datastudio.google.com/embed/reporting/3e900138-9cc1-4232-9fc9-2d3678c3cfcb/page/pMJ2C",
    challengeUrl:'https://datastudio.google.com/embed/reporting/9aff3db0-3ef2-46ec-b064-d31dcf5f4287/page/Ll2pC',
    orderBasicUrl: 'https://lookerstudio.google.com/embed/reporting/78d5caef-b818-4bf3-8aab-ccc05af6e88a/page/csq0D',
  },

  orderscentral_api: {
    apiKey: 'AIzaSyA07ubkK91Rkqv_92Tb_6sWNvNf4twWmJQ',
    base_url: 'https://orders-admin-mbsx99e.ue.gateway.dev/v1/admin',
  },
};
