// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
input.mat-chip-input {
  width: 100%;
}

.icon-help {
  font-size: 18px;
  margin: 0 0.5rem;
}

.name-id,
#point-values-header,
#grantors-header,
.point-values,
.point-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name-id mat-form-field,
#point-values-header mat-form-field,
#grantors-header mat-form-field,
.point-values mat-form-field,
.point-name mat-form-field {
  width: 45%;
}

#main-actions {
  display: flex;
  flex-direction: row-reverse;
}
#main-actions button {
  margin: 8px;
}

/* Superposición que cubre toda la pantalla */
.overlay {
  position: fixed; /* Fijado en toda la pantalla */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté sobre otros elementos */
}

/* Centra el spinner */
.spinner-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/preinvoice/preinvoice-change-status/preinvoice-change-status.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,WAAA;AAEJ;;AACA;EACI,eAAA;EACA,gBAAA;AAEJ;;AACA;;;;;EAKE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEF;AADE;;;;;EACE,UAAA;AAOJ;;AAHA;EACE,aAAA;EACA,2BAAA;AAMF;AAJE;EACE,WAAA;AAMJ;;AAFA,6CAAA;AACA;EACE,eAAA,EAAA,+BAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA,EAAA,4BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA,EAAA,2CAAA;AAKF;;AAFA,sBAAA;AACA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,0CAAA;AAKF","sourcesContent":["input.mat-chip-input {\n    width: 100%;\n}\n\n.icon-help{\n    font-size: 18px;\n    margin: 0 0.5rem;\n}\n\n.name-id,\n#point-values-header,\n#grantors-header,\n.point-values,\n.point-name {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  mat-form-field {\n    width: 45%;\n  }\n}\n\n#main-actions {\n  display: flex;\n  flex-direction: row-reverse;\n\n  button {\n    margin: 8px;\n  }\n}\n\n/* Superposición que cubre toda la pantalla */\n.overlay {\n  position: fixed; /* Fijado en toda la pantalla */\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Asegura que esté sobre otros elementos */\n}\n\n/* Centra el spinner */\n.spinner-container {\n  background: white;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
