// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  min-width: 100%;
}

.vertical-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.full-width {
  width: 100%;
}

.align-start {
  align-self: start;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/app-users/reset-user-dialog/reset-user-dialog.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/app-users/reset-user-dialog/reset-user-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;ACCJ;;ADEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;ACCJ;;ADCA;EACI,WAAA;ACEJ;;ADAA;EACI,iBAAA;ACGJ","sourcesContent":["form {\n    min-width: 100%;\n}\n\n.vertical-form {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n.full-width {\n    width: 100%;\n}\n.align-start {\n    align-self: start;\n}\n","form {\n  min-width: 100%;\n}\n\n.vertical-form {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.full-width {\n  width: 100%;\n}\n\n.align-start {\n  align-self: start;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
