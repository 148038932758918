import { AppsEditorProfileField } from './apps-editor/apps-editor-profile-field';
import {
  ProfileFieldsInput,
  ProfileFieldsOutput,
  ProfileFieldDefinitionInput,
} from '../../users-api';

export class ProfileFieldDefinitionUtils {
  static editorFieldToApiField(
    fieldData: AppsEditorProfileField
  ): ProfileFieldDefinitionInput {
    const object: ProfileFieldDefinitionInput = {
      title: fieldData.title,
      use_in_auth: fieldData.use_in_auth,
      public: fieldData.public,
      self_managed: fieldData.self_managed,
      autocomplete_url: fieldData.autocomplete_url,
      self_managed_programs: fieldData.self_managed_programs,
      required: fieldData.required,
      weight: fieldData.weight,
    };
    if (fieldData.validation_type === 'pattern') {
      if (fieldData.pattern) {
        const pattern = fieldData.pattern.trim();
        if (pattern) {
          object.pattern = pattern;
        }
      }
    } else if (fieldData.validation_type === 'format') {
      if (fieldData.format) {
        object.format = fieldData.format;
      }
    } else if (fieldData.validation_type === 'enum') {
      if (fieldData.enum && fieldData.enum.length) {
        // Turn all items to string and remove duplicates
        const unique = fieldData.enum.filter((v, i, a) => a.indexOf(v) === i);
        object.enum = unique;
      }
    }

    return object;
  }

  static editorDataToApiInput(
    editorData: Array<AppsEditorProfileField>
  ): ProfileFieldsInput {
    const profileInput: ProfileFieldsInput = {
      fields: {},
    };
    
    for (let i = 0; i < editorData.length; ++i) {
      const field = editorData[i];
      profileInput.fields[
        field.id
      ] = ProfileFieldDefinitionUtils.editorFieldToApiField(field);
    }
    console.log('profileInput', profileInput);
    
    return profileInput;
  }

  static apiOutputToEditorData(
    apiData: ProfileFieldsOutput
  ): Array<AppsEditorProfileField> {
    const editorFields: Array<AppsEditorProfileField> = [];
    Object.entries(apiData.fields).forEach(([id, apiField]) => {
      const field: AppsEditorProfileField = {
        id: id,
        title: apiField.title,
        use_in_auth: apiField.use_in_auth,
        public: apiField.public,
        locked: apiField.locked,
        validation_type: 'free',
        pattern: '',
        format: '',
        enum: [],
        weight: apiField.weight,
        self_managed: apiField.self_managed,
        self_managed_programs: apiField.self_managed_programs,
        autocomplete_url: apiField.autocomplete_url,
        required: apiField.required,
      };
      if ('enum' in apiField) {
        field.enum = apiField.enum;
        field.validation_type = 'enum';
      } else if ('pattern' in apiField) {
        field.pattern = apiField.pattern;
        field.validation_type = 'pattern';
      } else if ('format' in apiField) {
        field.format = apiField.format;
        field.validation_type = 'format';
      }
      editorFields.push(field);
    });
    return editorFields;
  }
}
