import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Menu } from '../../../../users-api';

export interface MenuModalData {
  mode: 'add' | 'edit';
  menu?: Menu;
}

@Component({
  selector: 'app-menu-modal',
  templateUrl: './menu-modal.component.html',
  styleUrls: ['./menu-modal.component.scss']
})
export class MenuModalComponent {
  menuForm: FormGroup;
  componentOptions = ['kpi', 'catalog', 'content', 'recognitions', 'wall', 'invoices', 'exhibitions', 'challenges', 'link'];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MenuModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MenuModalData
  ) {
    this.menuForm = this.fb.group({
      label: ['', [Validators.required, Validators.maxLength(100)]],
      component: ['', Validators.required],
      href: ['', Validators.maxLength(1024)],
      icon: ['', [Validators.required, Validators.maxLength(1024)]]
    });

    if (data.mode === 'edit' && data.menu) {
      this.menuForm.patchValue(data.menu);
    }
  }

  onSave(): void {
    if (this.menuForm.valid) {
      this.dialogRef.close(this.menuForm.value);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}