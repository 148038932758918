import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppsService, App as AppModel } from '../../../users-api';
import { MainSidenavService } from '../../../main-sidenav.service';

import { AppsEditorOutput } from '../apps-editor/apps-editor-output';
import { ProfileFieldDefinitionUtils } from '../profile-field-definition-utils';

@Component({
  selector: 'app-apps-add',
  templateUrl: './apps-add.component.html',
  styleUrls: ['./apps-add.component.scss'],
  providers: [AppsService],
})
export class AppsAddComponent implements OnInit {
  public appData: AppModel;
  public loading = true;

  constructor(
    private router: Router,
    private appsApi: AppsService,
    public mainSidenavService: MainSidenavService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.appData = {
      id: '',
      name: '',
      gcp: {
        main_project_id: '',
        recaptcha_auth: false,
        recaptcha_secret: '',
        recaptcha_enterprise: false,
        recaptcha_min_score: 0.5,
        recaptcha_keys_ids: [],
      },
      auth_jwt_info: {
        issuer: '',
        audiences: [],
        jwks_uri: '',
      },
      register_type: 'iddoc',
      puntored_recaudo: {
        factor: 0,
        offset_cents: 0,
      },
      card_terminal: {
        factor: 0,
        offset_cents: 0,
      },
      twilio: {
        verify_sid: '',
        token: '',
        sid: '',
      },
    };
    this.loading = false;
  }

  onEditorOK(editorData: AppsEditorOutput) {
    this.loading = true;
    this.appsApi.createApp(editorData.appData).subscribe(
      (ans) => {
        const profileData = ProfileFieldDefinitionUtils.editorDataToApiInput(
          editorData.profileFieldsData
        );
        if (Object.keys(profileData.fields).length) {
          this.appsApi
            .setAppProfileFields(editorData.appData.id, profileData)
            .subscribe(
              (ansProfile) => {
                this.loading = false;
                this.router.navigateByUrl('/pages/apps');
              },
              (errorProfile) => {
                console.error(
                  'Error calling setAppProfileFields',
                  errorProfile
                );
                this.loading = false;
                try {
                  const data = errorProfile.json();
                  this.snackBar.open(data.message, 'OK');
                } catch (ex) {
                  this.snackBar.open(
                    'Error llamando setAppProfileFields: ' + errorProfile,
                    'OK'
                  );
                }
              }
            );
        } else {
          this.loading = false;
          this.router.navigateByUrl('/pages/apps');
        }
      },
      (error) => {
        this.loading = false;
        console.error('Error in createApp', error);
        try {
          const data = error.error;
          this.snackBar.open(data.message, 'OK');
        } catch (ex) {
          this.snackBar.open('Ocurrió un error', 'OK');
        }
      }
    );
  }

  onEditorCancel() {
    this.router.navigateByUrl('/pages/apps');
  }
}
