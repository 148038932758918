/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { booleanAttribute, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { OrdersCentralService } from '../../../services/orders-central.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { currenciesForSelect } from '../../../data/currency-codes';
import { Courier, Couriers } from '../../../models/couriers/couriers-model';

@Component({
  selector: 'app-couriers-form',
  templateUrl: './couriers-form.component.html',
  styleUrls: ['./couriers-form.component.scss']
})
export class CouriersFormComponent implements OnInit {

  courierForm: FormGroup;
  mode = 'create';
  courierData: Couriers;
  saved = false;
  isSending = false;
  uriSeparatorKeyCodes = [ENTER, SPACE];
  fieldSeparatorKeyCodes = [ENTER];
  loading = true;

  name = 'Nueva Transportadora';
  currencies = currenciesForSelect;

  data;

  constructor(
    private formBuilder: FormBuilder,
    private couriersService: OrdersCentralService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.initConstructor();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.mode = 'edit';
      this.name = 'Editar Transportadora';
      this.getCourier(id);
    }
  }

  initConstructor() {
    this.courierForm = this.formBuilder.group({
      courier_name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      erp_code: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      courier_description: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      is_disabled: [
        booleanAttribute(false),
        Validators.compose([
          Validators.required,
        ]),
      ],
    });
  }

  getCourier(id){
    this.couriersService.getCourier(id).subscribe((res: Courier) => {
      this.data = res;
      this.courierForm.controls.courier_name.setValue(res.courier_name);
      this.courierForm.controls.courier_description.setValue(res.courier_description);
      this.courierForm.controls.erp_code.setValue(res.erp_code);
      this.courierForm.controls.is_disabled.setValue(res.is_disabled);
      this.courierForm.controls.courier_id.disable();
    });
  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/couriers');
  }

  async OKClicked() {
    this.loading = true;
    if(this.isSending) {
      return;
    }
    this.isSending = true;
    const body = {
      courier_name: this.courierForm.value.courier_name,
      erp_code: this.courierForm.value.erp_code,
      courier_description: this.courierForm.value.courier_description,
      is_disabled: this.courierForm.value.is_disabled,
    };
    console.log(body);

    if(this.mode === 'create'){
      this.couriersService.postCourier(body).subscribe((res: any) => {
        console.log(res);
        if(res.result === true){
          this.isSending = false;
          Swal.fire({
            icon: 'success',
            title: res.log.message,
            confirmButtonColor: '#ff9800',
          }).then(() => {
            Swal.close();
            this.loading = true;
            this.router.navigateByUrl('/pages/couriers');
          });
        } else {
          this.isSending = false;
          this.loading = false;
          console.error('Error in createCourier', res);
          const data = res;
          Swal.fire({
            icon: 'warning',
            title: data.message,
            confirmButtonColor: '#ff9800',
          });
          return;
        }
      },
      (err) => {
        console.error(err.error);
        const data = err.error;
        this.isSending = false;
        Swal.fire({
          icon: 'warning',
          title: data.message,
          confirmButtonColor: '#ff9800',
        });
      });
    } else {
      body['courier_id'] = this.route.snapshot.paramMap.get('id');
      this.couriersService.putCourier(body).subscribe((res: any) => {
        if(res.code === 'COURIER_UPDATED'){
          this.isSending = false;
          Swal.fire({
            icon: 'success',
            title: res.message,
            confirmButtonColor: '#ff9800',
          }).then(() => {
            Swal.close();
            this.loading = true;
            this.router.navigateByUrl('/pages/couriers');
          });
        }
      },
      (err) => {
        this.isSending = false;
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Error guardando',
          text: 'Por favor intente de nuevo'
        });
      });
    }

  }
}
