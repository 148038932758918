/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgramBaseBudget } from './programBaseBudget';
import { ProgramBaseErp } from './programBaseErp';
import { ProgramBaseLimitRedemption } from './programBaseLimitRedemption';
import { ProgramBaseNequi } from './programBaseNequi';

/**
 * An incentives program
 */
export interface ProgramBase {
    app_id: string;
    /**
     * ID of the program within the company
     */
    program_id: string;
    /**
     * ID of the company this program belongs to
     */
    company_id: string;
    /**
     * Program name
     */
    name: string;
    /**
     * Country two letter code (uppercase)
     */
    country: string;
    /**
     * The account where users of this program have their points
     */
    points_account_type: string;
    budget: ProgramBaseBudget;
    erp: ProgramBaseErp;
    disable_exchanges: boolean;
    nequi: ProgramBaseNequi;
    redemption_limit?: ProgramBaseLimitRedemption;
    menu?: Array<Menu>;
    tabs?: Array<Tabs>;
}

export type ComponentType = 'kpi' | 'catalog' | 'content' | 'recognitions' | 'wall' | 'invoices' | 'exhibitions' | 'challenges' | 'link';

export interface Menu {
  label: string;
  component: ComponentType;
  href?: string;
  weight: number;
  icon: string;
}

export interface Tabs {
  label: string;
  component: ComponentType;
  href?: string;
  weight: number;
  icon: string;
}
