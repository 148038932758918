/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileFieldDefinitionInput } from './profileFieldDefinitionInput';
import { ProfileFieldDefinitionOutputAllOf } from './profileFieldDefinitionOutputAllOf';


export interface ProfileFieldDefinitionOutput {
    title: string;
    pattern?: string;
    format?: string;
    enum?: Array<string>;
    use_in_auth?: boolean;
    public?: boolean;
    locked: boolean;
    required? : boolean;
    weight?: number;
    self_managed: boolean;
    self_managed_programs: Array<string>;
    autocomplete_url: string;
}

