/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompaniesListAllOf } from './companiesListAllOf';
import { CompanyOutput } from './companyOutput';
import { BaseList } from './baseList';


/**
 * A list of companies.
 */
export interface CompaniesList {
    next_page_url?: string;
    next_page_cursor?: string;
    prev_page_url?: string;
    prev_page_cursor?: string;
    first_page_cursor?: string;
    data: Array<CompanyOutput>;
}

