// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complex-filter {
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.card {
  margin: 0;
  padding: 2em;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-content {
  margin-top: 2em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/limits-redemption/limits-redemption.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/limits-redemption/limits-redemption.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;ACCF;;ADGA;EACE,SAAA;EACA,YAAA;ACAF;;ADGA,2HAAA;AACA;EACE,eAAA;ACAF","sourcesContent":[".complex-filter {\n  display: grid;\n  grid-template-columns: 7fr 1fr;\n}\n\n\n.card {\n  margin: 0;\n  padding: 2em;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\nmat-card-content {\n  margin-top: 2em;\n}\n\n",".complex-filter {\n  display: grid;\n  grid-template-columns: 7fr 1fr;\n}\n\n.card {\n  margin: 0;\n  padding: 2em;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\nmat-card-content {\n  margin-top: 2em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
