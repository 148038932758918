/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ivaForSelect, statesForSelect, typeProductForSelect, ivaOptions } from '../../../models/preinvoice/preinvoice-settings-model';
import { typeStateForSelect, typePointsForSelect, dataCalculatePreinvoice, preinvoiceDetail, changeStatePreinvoice, statePreinvoiceOptions } from '../../../models/preinvoice/preinvoice-model';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { OrdersCentralService } from '../../../services/orders-central.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-preinvoice-change-status',
  templateUrl: './preinvoice-change-status.component.html',
  styleUrls: ['./preinvoice-change-status.component.scss']
})
export class PreinvoiceChangeStatusFormComponent implements OnInit {

  preinvoiceChangeForm: FormGroup;
  preinvoiceData: preinvoiceDetail;
  saved = false;
  isSending = false;
  calculate = false;
  uriSeparatorKeyCodes = [ENTER, SPACE];
  fieldSeparatorKeyCodes = [ENTER];

  name = 'Cambiar Estado Prefactura';
  statesDelivery = statesForSelect;
  typeProduct = typeProductForSelect;
  ivasOptions = ivaForSelect;
  typeStateDelivery = typeStateForSelect;
  typePointsDelivery = typePointsForSelect;
  loadingCalculate = false;

  data;

  paramsPreinvoice: changeStatePreinvoice;
  idpreinvoice: string;
  state: string;

  constructor(
    private formBuilder: FormBuilder,
    private ordersCentralService: OrdersCentralService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.initConstructor();
  }
  
  ngOnInit() {  
    this.idpreinvoice = this.route.snapshot.paramMap.get('id');
    this.getPreinvoiceInfo(this.idpreinvoice); 
    this.state = this.route.snapshot.paramMap.get('state');
  }

  initConstructor() {
    this.preinvoiceChangeForm = this.formBuilder.group({
      invoice_number: [''],
      invoice_date: [''],
      filing_date: [''],   
      observaciones: [''],      
    });
  }

  async getPreinvoiceInfo(id) {
    return this.ordersCentralService.getPreinvoiceId(id).subscribe(async (ans: preinvoiceDetail) => {
      this.preinvoiceData = ans;
      console.log(this.preinvoiceData);
    });
  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/preinvoice');
  }


  async OKClicked() {
    this.loadingCalculate = true;
    if(this.isSending) {
      return;
    }
    this.isSending = true;

    console.log(this.preinvoiceChangeForm.controls.invoice_date.value)
    this.paramsPreinvoice = {
      idpreinvoice: this.idpreinvoice,
      state: this.state
    }

    if (this.state == 'invoiced') {
      this.paramsPreinvoice.invoice_number = this.preinvoiceChangeForm.controls.invoice_number.value;
      this.paramsPreinvoice.invoice_date = new Date(this.preinvoiceChangeForm.controls.invoice_date.value).toISOString();
    }

    if (this.state == 'filed') {
      this.paramsPreinvoice.filing_date = new Date(this.preinvoiceChangeForm.controls.filing_date.value).toISOString();
      this.paramsPreinvoice.observaciones = this.preinvoiceChangeForm.controls.observaciones.value; 
    }

    console.log(this.paramsPreinvoice);
  
    this.ordersCentralService.putPreinvoiceChangeState(this.paramsPreinvoice).subscribe((res: dataCalculatePreinvoice | any) => {
      if(res.updated === true || res.updated === false){
        this.isSending = false;
        this.loadingCalculate = false;
        Swal.fire({
          icon: 'success',
          title: res.message,
          confirmButtonColor: '#ff9800',
        }).then(() => {
          Swal.close();
          this.router.navigateByUrl('/pages/preinvoice');
        });
      }else {
        console.error('Error in changeStatus', res);
        const data = res;
        console.log(data);
        Swal.fire({
          icon: 'warning',
          title: data.message,
          confirmButtonColor: '#ff9800',
        });
        return;
      };
     });
  }

  statePreinvoiceVisible(item){
    return statePreinvoiceOptions[item];
  }

  ivasVisible(items){
    const ivas = items.map(item =>
      ivaOptions[item]);
    const listivas = ivas.join(', ');
    return listivas;
  }

}

