/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { preinvoiceSetting, preinvoiceSettings } from "../models/preinvoice/preinvoice-settings-model";
import { preinvoiceList, preinvoiceManual, changeStatePreinvoice } from "../models/preinvoice/preinvoice-model";
import { Observable } from "rxjs";
import { Courier, Couriers } from "../models/couriers/couriers-model";

@Injectable({
  providedIn: "root",
})
export class OrdersCentralService {
  private baseUrl = environment.orderscentral_api.base_url;
  private apiKey = environment.orderscentral_api.apiKey;

  constructor(private http: HttpClient) { }

  listSettingsPreinvoice(filterParams: any): Observable<preinvoiceSettings> {
    const headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const params = new HttpParams({ fromObject: filterParams });
    const uri = `${this.baseUrl}/settingspreinvoice?key=${this.apiKey}`;
    return this.http.get<preinvoiceSettings>(uri, { headers, params });
  }
  getSettingsPreinvoice(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.baseUrl}/settingspreinvoice/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  postSettingsPreinvoice(newSettings: preinvoiceSetting) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post(`${this.baseUrl}/settingspreinvoice?key=${this.apiKey}`, newSettings, { headers });
  }
  putSettingsPreinvoice(SettingsPreinvoice: preinvoiceSetting) {
    return this.http.put(
      `${this.baseUrl}/settingspreinvoice/${SettingsPreinvoice.settings_preinvoice_id}?key=${this.apiKey}`,
      SettingsPreinvoice
    );
  }
  listCouriers(): Observable<Couriers> {
    const headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.baseUrl}/courier?key=${this.apiKey}`;
    return this.http.get<Couriers>(uri, { headers });
    }
  getCourier(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.baseUrl}/courier/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  postCourier(newCourier: Courier) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post(`${this.baseUrl}/courier?key=${this.apiKey}`, newCourier, { headers });
  }
  putCourier(courier: Courier) {
    return this.http.put(
      `${this.baseUrl}/courier/${courier.courier_id}?key=${this.apiKey}`,
      courier
    );
  }
  listPreinvoice(filterParams: any): Observable<preinvoiceList> {
    const headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const params = new HttpParams({ fromObject: filterParams });
    const uri = `${this.baseUrl}/preinvoice?`;
    return this.http.get<preinvoiceList>(uri, { headers, params });
  }
  postPreinvoiceCalculate(newCalculatePreinvoice: preinvoiceManual) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post(`${this.baseUrl}/preinvoice/calculate?key=${this.apiKey}`, newCalculatePreinvoice, { headers });
  }
  postPreinvoiceProcess(newCalculatePreinvoice: preinvoiceManual) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post(`${this.baseUrl}/preinvoice/trigger?key=${this.apiKey}`, newCalculatePreinvoice, { headers });
  }
  getPreinvoiceId(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.baseUrl}/preinvoice/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  getPreinvoiceIdComponents(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.baseUrl}/preinvoice/components/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  putPreinvoiceChangeState(paramsState: changeStatePreinvoice){
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.put(`${this.baseUrl}/preinvoice/changeStatus?key=${this.apiKey}`, paramsState, { headers });
  }
}
