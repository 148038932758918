import { Component, HostListener, OnInit, Sanitizer } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})

export class OrderReportComponent implements OnInit {

  width = (window.innerWidth - 30)
  constructor(private sanitizer : DomSanitizer) { 
    this.sanitizer = sanitizer;  
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.width = (width - 30)
  }

  ngOnInit() {

  }
  URL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(environment.reports.orderBasicUrl);
  }
}
