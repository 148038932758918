/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppUserInputAllOf } from './appUserInputAllOf';
import { AppUserBase } from './appUserBase';


/**
 * App User data input, for creating a user.
 */
export interface AppUserInput {
    app_id: string;
    username: string;
    email?: string;
    phone?: string;
    roles?: Array<string>;
    programs?: Array<string>;
    enabled?: boolean;
    password?: string;
    profile?: { [key: string] : string[] };
    auth_ids?: string[];
    last_auth_id?: string;
    personal_data?: {
      iddoc_type?: string;
      iddoc?: string;
      name?: string;
      last_name?: string;
    };
}

