/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManagedEntity } from './managedEntity';
import { AppUserOutputAllOf } from './appUserOutputAllOf';
import { AppUserBase } from './appUserBase';


/**
 * App User with ID.
 */
export interface AppUserOutput {
    app_id: string;
    username: string;
    email?: string;
    email_verified?: boolean;
    phone?: string;
    phone_verified?: boolean;
    roles?: Array<string>;
    programs?: Array<string>;
    enabled: boolean;
    created_at: Date;
    created_by: string;
    updated_at?: Date;
    updated_by?: string;
    id: string;
    profile?: { [key: string]: Array<string>; };
    personal_data?: {
      iddoc_type?: string;
      iddoc?: string;
      name?: string;
      last_name?: string;
      icon?: {
        public_id?: string;
        url?: string;
        secure_url?: string;
      }
    };
    accounts? : any;
}

