import { ProgramBaseLimitRedemption } from './programBaseLimitRedemption';
/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManagedEntity } from './managedEntity';
import { ProgramBaseBudget } from './programBaseBudget';
import { ProgramBaseNequi } from './programBaseNequi';
import { ProgramBaseErp } from './programBaseErp';
import { ProgramBase } from './programBase';
import { ProgramFullAllOf } from './programFullAllOf';


export interface ProgramFull {
  app_id: string;
  disable_exchanges: boolean;
    /**
     * Global Unique ID
     */
    id: string;
    /**
     * ID of the program within the company
     */
    program_id: string;
    /**
     * ID of the company this program belongs to
     */
    company_id: string;
    /**
     * Program name
     */
    name: string;
    /**
     * Country two letter code (uppercase)
     */
    country: string;
    /**
     * The account where users of this program have their points
     */
    points_account_type: string;
    budget: ProgramBaseBudget;
    erp: ProgramBaseErp;
    nequi: ProgramBaseNequi;
    created_at: Date;
    created_by: string;
    updated_at?: Date;
    updated_by?: string;
    redemption_limit?: ProgramBaseLimitRedemption;
}

