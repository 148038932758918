import { StorageService } from './../../../services/storage.service';
import { ChallengeStatusType } from './../../../models/answers/answers-models';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IExhibition, IFormExhibitions } from '../exhibition/exhibition.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExhibitionsService {
  header = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private storageService: StorageService) { }


  getForms(appid) {
    const uriConnection = `${environment.exhibition_api.base_url}/${appid}/forms`;
    let headers = this.header
    return this.http.get<{ data: IFormExhibitions[] }>(`${uriConnection}`, { headers });
  }

  getForm(appid, id: string): Observable<IFormExhibitions> {
    let headers = this.header
    const uri = `${environment.exhibition_api.base_url}/${appid}/form/${id}`;;
    return this.http.get<IFormExhibitions>(uri, { headers });
  }

  putForm(form: IFormExhibitions, app_id) {
    const uriConnection = `${environment.exhibition_api.base_url}/${app_id}/form`;
    return this.http.put<IFormExhibitions>(
      `${uriConnection}/${form.id || 'default'}`,
      form
    );
  }

   getExhibitions(appid,hasParameters: boolean = false, parameters?: {
    uid?: string;
    createAt_start?: Date;
    createAt_end?: Date;
    status?: string;
    exhibition_name?: string;
  }) {
    const httpParameters = hasParameters && parameters
    ? Object.entries(parameters).reduce((params, [key, value]) => {
        if (value !== undefined && value !== null) {
          const formattedValue = value instanceof Date ? value.toISOString() : value.toString();
          return params.set(key, formattedValue);
        }
        return params;
      }, new HttpParams())
    : new HttpParams();

    const uriConnection = `${environment.exhibition_api.base_url}/${appid}/exhibitions`;
    let headers = this.header
    return this.http.get<{ data: IExhibition[] }>(`${uriConnection}`, { headers,params:httpParameters });
  }

  getExhibition(appid, exhibition_id,userId): Observable<IExhibition> {
    let headers = this.header
    const account = this.storageService.get('account');

    const uri = `${environment.exhibition_api.base_url}/${appid}/users/${userId}/exhibitions/${exhibition_id}`;  
    return this.http.get<IExhibition>(uri, { headers });
  }

  updateExhibitionsStatus(appid,uid,  exhibition_id, newStatus: ChallengeStatusType,dataConfirmation?) {
    let headers = this.header
    const data  =  {
      status: newStatus,
      rejectionCause: dataConfirmation.rejectionCause,
      comment: dataConfirmation.comment}

    const uri = `${environment.exhibition_api.base_url}/${appid}/users/${uid}/exhibitions/${exhibition_id}`;  
    return this.http.put<IExhibition>(uri,data, { headers });
  }


}
