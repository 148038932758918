/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information. 
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { App } from '../model/app';
import { AppOutput } from '../model/appOutput';
import { AppsList } from '../model/appsList';
import { CreatedAnswer } from '../model/createdAnswer';
import { ErrorAnswer } from '../model/errorAnswer';
import { ProfileFieldsInput } from '../model/profileFieldsInput';
import { ProfileFieldsOutput } from '../model/profileFieldsOutput';
import { UnconstrainedApp } from '../model/unconstrainedApp';
import { UpdatedAnswer } from '../model/updatedAnswer';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AppsService {

    protected basePath = 'https://users-174020.appspot.com/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    /**
     * Register a new app or program.
     * @param app App object to create.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createApp(app: App, observe?: 'body', reportProgress?: boolean): Observable<CreatedAnswer>;
    public createApp(app: App, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedAnswer>>;
    public createApp(app: App, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedAnswer>>;
    public createApp(app: App, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling createApp.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreatedAnswer>(`${this.configuration.basePath}/apps`,
            app,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List apps
     * @param page_size Page size for pagination. Default: 10.
     * @param page_start_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param page_end_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param first_page_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findApp(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<AppsList>;
    public findApp(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppsList>>;
    public findApp(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppsList>>;
    public findApp(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page_size !== undefined && page_size !== null) {
            queryParameters = queryParameters.set('page_size', <any>page_size);
        }
        if (page_start_cursor !== undefined && page_start_cursor !== null) {
            queryParameters = queryParameters.set('page_start_cursor', <any>page_start_cursor);
        }
        if (page_end_cursor !== undefined && page_end_cursor !== null) {
            queryParameters = queryParameters.set('page_end_cursor', <any>page_end_cursor);
        }
        if (first_page_cursor !== undefined && first_page_cursor !== null) {
            queryParameters = queryParameters.set('first_page_cursor', <any>first_page_cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<AppsList>(`${this.configuration.basePath}/apps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update some fields of App object.
     * Only change the properties in the given object.
     * @param appId ID of App to return.
     * @param body Properties to update.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchApp(appId: string, body: UnconstrainedApp, observe?: 'body', reportProgress?: boolean, params?): Observable<UpdatedAnswer>;
    public patchApp(appId: string, body: UnconstrainedApp, observe?: 'response', reportProgress?: boolean, params?): Observable<HttpResponse<UpdatedAnswer>>;
    public patchApp(appId: string, body: UnconstrainedApp, observe?: 'events', reportProgress?: boolean, params?): Observable<HttpEvent<UpdatedAnswer>>;
    public patchApp(appId: string, body: UnconstrainedApp, observe: any = 'body', reportProgress: boolean = false, params?): Observable<any> {
        if (appId === null || appId === undefined) {
            throw new Error('Required parameter appId was null or undefined when calling patchApp.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchApp.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let URL = `${this.configuration.basePath}/apps/${encodeURIComponent(String(appId))}`;

        if(params){
            let queryParameters = new HttpParams({encoder: this.encoder});
            for (let param in params) {
                if (params.hasOwnProperty(param)) {
                    queryParameters = queryParameters.set(param, params[param]);
                }
            }
            URL = `${URL}?${queryParameters.toString()}`;
        }

        return this.httpClient.patch<UpdatedAnswer>(URL,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get App by ID.
     * Returns an App object.
     * @param appId ID of App to return.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveApp(appId: string, observe?: 'body', reportProgress?: boolean): Observable<AppOutput>;
    public retrieveApp(appId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppOutput>>;
    public retrieveApp(appId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppOutput>>;
    public retrieveApp(appId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (appId === null || appId === undefined) {
            throw new Error('Required parameter appId was null or undefined when calling retrieveApp.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<AppOutput>(`${this.configuration.basePath}/apps/${encodeURIComponent(String(appId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get App\&#39;s Profile fields definition.
     * @param appId ID of App.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveAppProfileFields(appId: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileFieldsOutput>;
    public retrieveAppProfileFields(appId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileFieldsOutput>>;
    public retrieveAppProfileFields(appId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileFieldsOutput>>;
    public retrieveAppProfileFields(appId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (appId === null || appId === undefined) {
            throw new Error('Required parameter appId was null or undefined when calling retrieveAppProfileFields.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<ProfileFieldsOutput>(`${this.configuration.basePath}/apps/${encodeURIComponent(String(appId))}/profile_fields`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set app profile fields configuration
     * @param appId ID of App.
     * @param profile_fields Profile fields description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setAppProfileFields(appId: string, profile_fields: ProfileFieldsInput, observe?: 'body', reportProgress?: boolean): Observable<UpdatedAnswer>;
    public setAppProfileFields(appId: string, profile_fields: ProfileFieldsInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdatedAnswer>>;
    public setAppProfileFields(appId: string, profile_fields: ProfileFieldsInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdatedAnswer>>;
    public setAppProfileFields(appId: string, profile_fields: ProfileFieldsInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (appId === null || appId === undefined) {
            throw new Error('Required parameter appId was null or undefined when calling setAppProfileFields.');
        }
        if (profile_fields === null || profile_fields === undefined) {
            throw new Error('Required parameter profile_fields was null or undefined when calling setAppProfileFields.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdatedAnswer>(`${this.configuration.basePath}/apps/${encodeURIComponent(String(appId))}/profile_fields`,
            profile_fields,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update App object.
     * Change all App information.
     * @param appId ID of App to return.
     * @param app App object to create.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateApp(appId: string, app: App, observe?: 'body', reportProgress?: boolean): Observable<UpdatedAnswer>;
    public updateApp(appId: string, app: App, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdatedAnswer>>;
    public updateApp(appId: string, app: App, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdatedAnswer>>;
    public updateApp(appId: string, app: App, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (appId === null || appId === undefined) {
            throw new Error('Required parameter appId was null or undefined when calling updateApp.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling updateApp.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdatedAnswer>(`${this.configuration.basePath}/apps/${encodeURIComponent(String(appId))}`,
            app,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
