import { PERMISSIONS, PermissionsService } from './../../services/permissions/permissions.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  switchMap,
  startWith,
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { AppUserOutput } from '../../users-api/model/appUserOutput';
import { UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { StorageService } from '../../services/storage.service';
import {
  ExhibitionAnswer,
  INVOICES_ANSWER_STATUSES,
  INVOICES_STATUS_OPTIONS } from '../../models/answers/answers-models';
import { AuthService } from '../../services/auth.service';
// import {formatDate} from '@angular/common';
import moment from 'moment';
import { ExhibitionsService } from '../exhibitions/services/exhibitions.service';
@Component({
  selector: 'app-answers-exhibiciones',
  templateUrl: './answers-exhibiciones.component.html',
  styleUrls: ['./answers-exhibiciones.component.scss']
})
export class AnswersExhibicionesComponent implements OnInit {
  loading = false;
  displayedColumns = ['answer'];
  pageSize = 100;
  hoveredRow: any;
  dataSource: Observable<ExhibitionAnswer[]>;
  uidFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idOptions$: Observable<string[]>;
  idControl = new UntypedFormControl('');
  statusControl = new UntypedFormControl('', Validators.required);
  startDateControl = new UntypedFormControl(Validators.required);
  endDateControl = new UntypedFormControl();
  currentUser: AppUserOutput;
  currentAppId: string;
  statusOptions: {
    label: string;
    value: string;
  }[];
  allowedAppIds: string[];
  allowAllAppIds = false;
  requiredFields = true;
  resultsFound = true;
  foundNumber: number;
  resultsNumberLimit = 100;
  dataArrayToExcel = [];
  dataSearch;
  dataFilter;
  constructor(
    @Inject('ExhibitionsFireAuth') private exhibitionsAuth: AngularFireAuth,
    @Inject('ExhibitionsFirestore') private exhibitionFire: AngularFirestore,
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private exhibitionsService: ExhibitionsService,
    private permissions : PermissionsService
  ) {
    this.getUserInfo();
    this.getExhibitions();
  }

  async ngOnInit() {
    await this.initAllowedIds();
    this.statusOptions = [{
      label: 'Seleccionar',
      value: ''
    }];
    this.statusOptions.push(...INVOICES_STATUS_OPTIONS);
    this.idOptions$ = combineLatest([
      this.appIdFilter$.pipe(distinctUntilChanged()),
      this.idControl.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged()
      )
    ]).pipe(
      switchMap(([appId, prefix]) => {
        if (!this.allowAllAppIds && (!this.allowedAppIds || this.allowedAppIds.length === 0)) {
          return of([]);
        }
        if (!prefix || !prefix.trim()) {
          this.idFilter$.next(null);
          return of([]);
        }
        return this.exhibitionFire.collection('ExhibitionsAnswers', ref => {
          const trimmedPrefix = prefix.trim();
          let query = ref.limit(10);
          query = query.where('exhibitionName', '>=', trimmedPrefix);
          query = query.where('exhibitionName', '<=', `${trimmedPrefix}~`);
          const appIds = appId ? [appId] : (this.allowAllAppIds ? null : this.allowedAppIds);
          if (appIds) {
            query = query.where('appId', 'in', appIds);
          }
          return query;
        }).valueChanges({ idField: 'id' });
      }),
      map(
        items => items
          .map(i => i.exhibitionName)
          .filter((t, i, self) => self.indexOf(t) === i)
      ),
      catchError(err => {
        console.error(err);
        Swal.fire('Error', err.message || err, 'error');
        return of([]);
      })
    );
    this.idOptions$.subscribe((data)=> {
      this.dataSearch = data;
    });
    this.startDateControl.markAsTouched();
    this.statusControl.markAsTouched();
  }

  async initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    if (await this.permissions.hasPermission(PERMISSIONS.ACCESS_ALL)){
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  async getUserInfo() {
    const challengesUser = await this.authService.getRemoteFirebaseUser(
      this.exhibitionsAuth,
      'challenges'
    );
  }

  getExhibitions() {
    this.dataSource = combineLatest([
      this.uidFilter$.pipe(distinctUntilChanged()),
      this.appIdFilter$.pipe(distinctUntilChanged()),
      this.idFilter$.pipe(startWith(''), distinctUntilChanged()),
      this.statusControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
      this.startDateControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
      this.endDateControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
    ]).pipe(
      map(data => {
        this.loading = true;
        return data;
      }),
      switchMap(
        ([uid, appId, id, status, startDate, endDate]) => {
          if (!this.allowAllAppIds
            && (!this.allowedAppIds
              || !this.allowedAppIds.length
              || (appId && !this.allowedAppIds.includes(appId)))) {
            return of([]);
          }
          this.requiredFields = appId && status && startDate;
          if (!this.requiredFields) {
            return of([]);
          }
          // En algunas apps (como Rotoplas MX) pusieron mal el app_id con el
          // que se envían las respuestas, así que debemos revisar varios app_id
          // por alguna razón casi siempre cambian el guión abajo (_)
          // por guión en el medio (-).
          // NOTESE que cuando corrijan el app_id en la aplicación (front)
          // esto seguirá funcionando porque tiene en cuenta ambos casos.
          const appIds: string[] = [];
          if (appId) {
            appIds.push(appId);
            const altAppId = appId.replace(/_/g, '-');
            if (altAppId !== appId) {
              appIds.push(altAppId);
            }
          }
          const params = {}
          if (uid) { params['uid'] = uid; }
          if (appId) { params['appId'] = appId; }
          if (id) { params['exhibition_name'] = id.trim(); }
          if (status) { params['status'] = status; }
          if (startDate) { params['createAt_start'] = moment(startDate).toDate(); }
          if (endDate) { params['createAt_end'] = moment(endDate).add(23, 'hours').add(59, 'minutes').toDate(); }
          
          return this.exhibitionsService.getExhibitions(appId,true,params)
           /* return this.exhibitionFire.collection('ExhibitionsAnswers', ref => {
            let query = ref.limit(this.resultsNumberLimit);
            query = uid ? query.where('uid', '==', uid) : query;
            query = appId ? query.where('appId', 'in', appIds) : query;
            query = id ? query.where('exhibitionName', '==', id.trim()) : query;
            query = status ? query.where('status', '==', status) : query;
            query = startDate ? query.where('timestamp', '>=', moment(startDate).toDate()) : query;
            query = endDate ? query.where('timestamp', '<=', moment(endDate).add(23, 'hours').add(59, 'minutes').toDate()) : query;
            if (!appId && !this.allowAllAppIds) {
              // in operator allows at most 10 options
              query = query.where('appId', 'in', this.allowedAppIds.slice(0, 10));
            }
            return query;
          }).valueChanges({ idField: 'id' });  */
        }
      ),
      map((data :any) => {
        this.loading = false;
        console.log(data);
        this.resultsFound = data.length > 0;
        this.foundNumber = data.length;
        this.dataArrayToExcel = data;
        return data.map((a: ExhibitionAnswer) => {
          a.statusLabel = INVOICES_ANSWER_STATUSES[a.status];
          if (a.fields) {
            a.textFields = a.fields.filter(i => (i.type !== 'file' && i.type !== 'camera')).map(item => {
              if (item.name === 'date') {
                item['value'] = moment(item.value).format('DD MMM YYYY HH:mm:ss');
              }
              return item;
            });
            a.imgFields = a.fields.filter(i => (i.type === 'file' || i.type === 'camera'));
          }
          return a;
        });
      }),
      catchError(err => {
        this.loading = false;
        console.error(err);
        Swal.fire('Error', err.message || err, 'error');
        return of([]);
      })
    ) as Observable<ExhibitionAnswer[]>;

  }

  clearData(value){
    const val = String(value.target.value).toLowerCase();
    if(!val){
    // this.getRecognitions(this.currentAppId, this.currentProgramId, this.startDate, this.endDate);
    } else {
      console.log(this.dataSearch);
      
      // this.dataFilter = this.dataSearch.filter(x => String(x.translations[0].texts[0].name).toLowerCase().includes(val))
    }
  }

  onUserSelected(user: AppUserOutput) {
    this.uidFilter$.next(user.id);
    this.currentUser = user;
  }

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
  }

  onIdOptionSelected() {
    this.idFilter$.next(this.idControl.value);
  }

  resetUid() {
    this.currentUser = null;
    this.uidFilter$.next(null);
  }

  resetApp() {
    this.currentAppId = null;
    this.uidFilter$.next(null);
  }

  onEdit(exhibition: ExhibitionAnswer) {
    const url = this.router.createUrlTree(['pages', 'exhibitions',this.currentAppId, exhibition.exhibition_id,exhibition.uid]);
    window.open(url.toString(), '_blank');
    return false;
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  userFilterClosed() {
    this.resetUid();
  }

}
