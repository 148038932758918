// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jwt-info-wrapper {
  display: flex;
  flex-direction: column;
}

.services-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.apps-wrapper {
  display: flex;
  width: 100%;
}

.btn {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/service-accounts/service-accounts-edit/service-accounts-edit.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/service-accounts/service-accounts-edit/service-accounts-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;ACCF;;ADEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,UAAA;ACCF;;ADEA;EACE,aAAA;EACA,yBAAA;EACA,WAAA;ACCF;;ADCA;EACE,aAAA;EACA,WAAA;ACEF;;ADCA;EACE,iBAAA;ACEF","sourcesContent":[".jwt-info-wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.services-wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 70%;\n}\n\n.button-wrapper {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n}\n.apps-wrapper {\n  display: flex;\n  width: 100%;\n}\n\n.btn {\n  margin-left: 10px;\n}\n",".jwt-info-wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.services-wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 70%;\n}\n\n.button-wrapper {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n}\n\n.apps-wrapper {\n  display: flex;\n  width: 100%;\n}\n\n.btn {\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
