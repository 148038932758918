/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { CompaniesUtilService } from '../../../services/companies-util.service';
import { CompanyOutput, ProgramFull } from '../../../users-api';
import { ivaForSelect, statesForSelect, typeProductForSelect } from '../../../models/preinvoice/preinvoice-settings-model';
import { typeStateForSelect, typePointsForSelect, dataCalculatePreinvoice, preinvoiceManual } from '../../../models/preinvoice/preinvoice-model';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { OrdersCentralService } from '../../../services/orders-central.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramsUtilService } from '../../../services/programs-util.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-preinvoice-form',
  templateUrl: './preinvoice-form.component.html',
  styleUrls: ['./preinvoice-form.component.scss']
})
export class PreinvoiceFormComponent implements OnInit {
  
  preinvoiceForm: FormGroup;
  mode = 'create';
  preinvoiceData: dataCalculatePreinvoice;
  saved = false;
  isSending = false;
  calculate = false;
  uriSeparatorKeyCodes = [ENTER, SPACE];
  fieldSeparatorKeyCodes = [ENTER];

  name = 'Nueva Prefactura';
  statesDelivery = statesForSelect;
  typeProduct = typeProductForSelect;
  ivasOptions = ivaForSelect;
  typeStateDelivery = typeStateForSelect;
  typePointsDelivery = typePointsForSelect;
  loadingCalculate = false;

  data;
  //Companies
  companiesOptions: string[] = [];
  filteredCompaniesOptions: string[];
  objectsCompaniesData: CompanyOutput[];
  companiesNameMap: Map<string, CompanyOutput> = new Map();
  companiesIdMap: Map<string, CompanyOutput> = new Map();
  companyId = "";
  companyNameInputControl = new UntypedFormControl();

  //Programs
  programsOptions = [];
  filteredProgramsOptions: Observable<string[]>;
  programs = [];

  paramsPreinvoice: preinvoiceManual;

  constructor(
    private formBuilder: FormBuilder,
    private ordersCentralService: OrdersCentralService,
    private router: Router,
    private route: ActivatedRoute,
    private companiesUtilService: CompaniesUtilService,
    private programsUtilService: ProgramsUtilService,
    private renderer: Renderer2, 
    private el: ElementRef
  ) {
    this.initConstructor();
  }
  
  ngOnInit() {
    this.initCompanies();

    // Filter programs
    this.filteredProgramsOptions = this.preinvoiceForm.controls.programInputControl.valueChanges
      .pipe(startWith(null))
      .pipe(
        map((val: any) =>
          val
          ? this.filtersAutoComplete(val, 'programsOptions')
          : this.programsOptions.slice()
        )
      );
    // Filter app names
    this.companyNameInputControl.valueChanges
      .pipe(startWith(null))
      .subscribe((val: string) => {
        if (val) {
          const nameParts = val.split('|').map((part: string) => part.trim());
          let company =
            nameParts.length > 1
              ? this.companiesIdMap.get(nameParts[1])
              : this.companiesIdMap.get(nameParts[0].toLowerCase());
          company = company ? company : this.companiesNameMap.get(nameParts[0].toLowerCase());
    
          const value = val.split(' | ').length > 1 ? val.split(' | ')[1] : val;
          this.companyId = value;
          const findObs = this.programsUtilService.getProgramsCompany(value);
          findObs.then((programsList: ProgramFull[]) => {
              if (!programsList) {
                return;
              }
              const ids = programsList.map((program) => program.id);
              this.programsOptions.push(...ids);
              this.preinvoiceForm.controls.programInputControl.setValue('', { emitEvent: true });
            });
          }
    
          this.filteredCompaniesOptions = val
            ? this.filtersAutoComplete(val, 'companiesOptions')
            : this.companiesOptions.slice();
      });
    
    const id = this.route.snapshot.paramMap.get('id');
  }

  initConstructor() {
    this.preinvoiceForm = this.formBuilder.group({
      programInputControl: [
        "",
      ],
      typeStateDeliveryOptions: [
        typeStateForSelect,
        Validators.compose([
          Validators.required,
        ]),
      ],
      typePointsDeliveryOptions: [
        typePointsForSelect,
        Validators.compose([
          Validators.required,
        ]),
      ],
      typeProductOptions: [
        typeProductForSelect,
        Validators.compose([
          Validators.required,
        ]),
      ],
      date_finish: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      estimate: [
        0,
        Validators.compose([
          Validators.min(0),
        ])
      ],
      ivas: [
        ivaForSelect,
        Validators.compose([
          Validators.required,
        ]),
      ],    
      deliveries: [
        '',
      ],      
    });
  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }
  

  cancelClicked() {
    this.router.navigateByUrl('/pages/preinvoice');
  }

  toDataURL(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  setCompanies(companies: CompanyOutput[]) {
    this.objectsCompaniesData = companies;
    this.companiesOptions = companies.map(
      (company: CompanyOutput) => `${company.name} | ${company.id}`
    );    
    this.companiesNameMap = companies.reduce(
      (companiesMap: Map<string, CompanyOutput>, company: CompanyOutput) => {
        companiesMap.set(company.name.toLowerCase(), company);
        return companiesMap;
      },
      new Map()
    );    
    this.companiesIdMap = companies.reduce(
      (companiesMap: Map<string, CompanyOutput>, company: CompanyOutput) => {
        companiesMap.set(company.id, company);
        return companiesMap;
      },
      new Map()
    );
  }
  
  async initCompanies() {
    try {
      const companies = await this.companiesUtilService.getCompanies();
      this.setCompanies(companies);
    } catch (error) {
      console.error('Error getting apps', error);
    }
  }

  addProgram(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();
      if (value && input) {
        // Check input program is known
        if (this.programsOptions.indexOf(value) < 0) {
          return;
        }
        // Init property if non existent
        if (!this.programs) {
          this.programs = [];
        }
        // Check value is not repeated
        if (this.programs.indexOf(value) >= 0) {
          return;
        }
        // Add value
        this.programs.push(value);
        input.value = '';
      }
    }
  
    removeProgram(program: string) {
      const index = this.programs.indexOf(program);
      if (index >= 0) {
        this.programs.splice(index, 1);
      }
    }

    async OKClicked() {
      this.loadingCalculate = true;
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
      if(this.isSending) {
        return;
      }
      this.isSending = true;
  
      const programFull = this.programs[0] ? this.programs[0] : this.preinvoiceForm.controls.programInputControl.value;     
      const partProgram = programFull.split('/');
      const ivasString = this.preinvoiceForm.controls.ivas.value;
      const ivasNumeros = ivasString.map(Number);
      this.paramsPreinvoice = {
        'program_id':  partProgram[3],
        'customer_id': this.companyId,
        'type_redemption': this.preinvoiceForm.controls.typePointsDeliveryOptions.value,
        'type_preinvoice': this.preinvoiceForm.controls.typeStateDeliveryOptions.value,
        'type_product': this.preinvoiceForm.controls.typeProductOptions.value,
        'estimate': this.preinvoiceForm.controls.estimate.value,
        'ivas': ivasNumeros,
        'date_finish': new Date(this.preinvoiceForm.controls.date_finish.value).toISOString(),
        'deliveries': this.preinvoiceForm.controls.deliveries.value,
      }
  
      if(this.mode === 'create'){
        this.ordersCentralService.postPreinvoiceCalculate(this.paramsPreinvoice).subscribe((res: dataCalculatePreinvoice | any) => {
          if(res.nro_deliveries && res.nro_deliveries >= 1){
            setTimeout(() => {
              this.isSending = false;
              this.preinvoiceData = res;
              this.calculate = true;
              this.loadingCalculate = false;
              console.log(this.preinvoiceData);
              document.getElementById('resultCard')?.scrollIntoView({behavior: 'smooth' });
            }, 100); // Simulación de carga            
          }else{
            console.error('Error in OKClicked', res);
            const data = res;
            this.isSending = false;
            this.loadingCalculate = false;
            Swal.fire({
              icon: 'warning',
              title: data.message,
              confirmButtonColor: '#ff9800',
            });
          }
        },
        (err) => {
          console.error(err.error);
          const data = err.error;
          this.isSending = false;
          Swal.fire({
            icon: 'warning',
            title: data.message,
            confirmButtonColor: '#ff9800',
          });
        });
      }  
    }

    async triggerPreinvoice(){
      this.loadingCalculate = true;
      const dataPreinvoice = this.paramsPreinvoice;
      console.log(dataPreinvoice);
      this.ordersCentralService.postPreinvoiceProcess(dataPreinvoice).subscribe((res: any) => {
        if(res.code === 'PROCESS_PREINVOICE_MANUAL'){
          this.isSending = false;
          this.loadingCalculate = false;
          Swal.fire({
            icon: 'success',
            title: res.message,
            confirmButtonColor: '#ff9800',
          }).then(() => {
            Swal.close();
            this.router.navigateByUrl('/pages/preinvoice');
          });
        } else {
          console.error('Error in triggerPreinvoice', res);
          const data = res;
          Swal.fire({
            icon: 'warning',
            title: data.message,
            confirmButtonColor: '#ff9800',
          });
          return;
        }
      });
    }
    setLoading(state: boolean) {
      this.loadingCalculate = state;
      if (state) {
        this.scrollToTop();
      }
    }
  
    private scrollToTop() {
      this.renderer.setProperty(this.el.nativeElement, 'scrollTop', 0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

