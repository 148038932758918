import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tabs } from '../../../../users-api';

export interface TabModalData {
  mode: 'add' | 'edit';
  tab?: Tabs;
}

@Component({
  selector: 'app-tab-modal',
  templateUrl: './tab-modal.component.html',
  styleUrls: ['./tab-modal.component.scss']
})
export class TabModalComponent {
  tabsForm: FormGroup;
  componentOptions = ['kpi', 'catalog', 'content', 'recognitions', 'wall', 'invoices', 'exhibitions', 'challenges', 'link'];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TabModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TabModalData
  ) {
    this.tabsForm = this.fb.group({
      label: ['', [Validators.required, Validators.maxLength(100)]],
      component: ['', Validators.required],
      href: ['', Validators.maxLength(1024)],
      icon: ['', [Validators.required, Validators.maxLength(1024)]]
    });

    if (data.mode === 'edit' && data.tab) {
      this.tabsForm.patchValue(data.tab);
    }
  }

  onSave(): void {
    if (this.tabsForm.valid) {
      this.dialogRef.close(this.tabsForm.value);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}