// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 1em;
}
.tab-container button {
  margin-left: 3%;
}
.tab-container .tab-document {
  margin-inline: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/allies-edit/allies-edit.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/pointz/allies-edit/allies-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,eAAA;ACCF;ADCE;EACE,eAAA;ACCJ;ADCE;EACE,kBAAA;ACCJ","sourcesContent":[".tab-container {\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n  margin-top: 1em;\n\n  button {\n    margin-left: 3%;\n  }\n  .tab-document{\n    margin-inline: 1px;\n  }\n}\n",".tab-container {\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n  margin-top: 1em;\n}\n.tab-container button {\n  margin-left: 3%;\n}\n.tab-container .tab-document {\n  margin-inline: 1px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
