import { PERMISSIONS } from './../../../services/permissions/permissions.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';

import { AppsEditorOutput } from './apps-editor-output';
import { AppsEditorProfileField } from './apps-editor-profile-field';

import { App as AppInterface, ProfileFieldsOutput, ProgramsList, ProgramsService } from '../../../users-api';
import { Observable } from 'rxjs';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { LockRedemptionComponent } from '../lock-redemption/lock-redemption.component';

@Component({
  selector: 'app-apps-editor',
  templateUrl: './apps-editor.component.html',
  styleUrls: ['./apps-editor.component.scss'],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
})
export class AppsEditorComponent implements OnInit {
  @ViewChild('lock_redemption',{static:true}) lock_redemption: LockRedemptionComponent;
  @Input() appData: AppInterface = {
    name: '',
    id: '',
    gcp: {
      main_project_id: '',
      recaptcha_auth: false,
      recaptcha_secret: '',
      recaptcha_enterprise: false,
      recaptcha_min_score: 0.5,
      recaptcha_keys_ids: [],
    },
    auth_jwt_info: {
      issuer: '',
      audiences: [],
      jwks_uri: '',
    },
    puntored_recaudo:{
      factor:0,
      offset_cents : 0
    },
    card_terminal:{
      factor:0,
      offset_cents : 0
    },
    register_type: '',
    twilio: {
      verify_sid: '',
      token: '',
      sid: '',
    },
  };

  @Input() profileFields: AppsEditorProfileField[] = [];
  @Input() mode = 'create';

  @Output() onOK = new EventEmitter<AppsEditorOutput>();
  @Output() onCancel = new EventEmitter<void>();

  disable_register_type = false;
  uriSeparatorKeyCodes = [ENTER, COMMA];

  programsOptions: string[] = [];
  filteredProgramsOptions: Observable<string[]>;

  appProfileDefinition: ProfileFieldsOutput;
  // appProfileFields: Array<AppUsersEditorFieldData> = [];
  loadingAppProfileDefinition = false;
  programIdsByAppMap = new Map<string, string[]>();
  programInputControl = new FormControl();
  recaptchaKeysWithCommas = '';
  PERMISSIONS = PERMISSIONS;
  constructor(private snackBar: MatSnackBar,private programsApi: ProgramsService) {}

  async ngOnInit() {
    await this.adjustAppData();
    if(this.mode === 'create'){
      (document.getElementById('recaptcha_secret') as HTMLInputElement).required = true;
      this.appData.gcp.recaptcha_auth = true;
    }
    if(this.mode  === 'edit'){
      if(this.appData.register_type){
        //   this.disable_register_type = true;
      }
    }
    this.loadPrograms();
    // Filter programs
    this.filteredProgramsOptions = this.programInputControl.valueChanges
      .pipe(startWith(null))
      .pipe(
        map((val) =>
          val
            ? this.filtersAutoComplete(val, 'programsOptions')
            : this.programsOptions.slice()
        )
      );
      if (this.appData.twilio === undefined) {
        this.appData.twilio = {
          verify_sid: '',
          token: '',
          sid: '',
        };
      }
  }

  async adjustAppData(){
    if (!this.appData) {
      return;
    }
    if (this.appData.gcp === undefined){
      this.appData.gcp = {
        main_project_id: '',
        recaptcha_auth: false,
        recaptcha_secret: '',
        recaptcha_enterprise: false,
        recaptcha_keys_ids: [],
        recaptcha_min_score: 0.5,
      };
    }
    if (this.appData.gcp.recaptcha_enterprise === undefined) {
      this.appData.gcp.recaptcha_enterprise = false;
      this.appData.gcp.recaptcha_keys_ids = [];
      this.appData.gcp.recaptcha_min_score = 0.5;
    }
    if (this.appData.gcp && this.appData.gcp.recaptcha_keys_ids) {
      this.recaptchaKeysWithCommas =
        this.appData.gcp.recaptcha_keys_ids.join(',');
    }
    if (this.appData.puntored_recaudo === undefined) {
      this.appData.puntored_recaudo = {
        factor:0,
        offset_cents : 0
      };
    }
    if(this.appData.card_terminal === undefined){
      this.appData.card_terminal = {
        factor:0,
        offset_cents : 0
      };
    }
  }

  okClicked() {
    let remove_missing_fields = false;
    if (this.appData &&
       this.appData.gcp &&
       this.recaptchaKeysWithCommas) {
      this.appData.gcp.recaptcha_keys_ids = this.recaptchaKeysWithCommas
        .split(',')
        .map(key => key.trim());
    }
    if (this.appData &&
       this.appData.gcp && this.appData.gcp.recaptcha_auth) {
      if (!this.appData.gcp.recaptcha_enterprise &&
          !this.appData.gcp.recaptcha_secret) {
        alert('Se debe agregar el Recaptcha Secret.');
        return;
      }
      if (this.appData.gcp.recaptcha_enterprise) {
        if (!this.appData.gcp.recaptcha_keys_ids.length) {
          alert('Se deben agregar Recaptcha Key IDs.');
          return;
        }
        if (this.appData.gcp.recaptcha_min_score) {
          this.appData.gcp.recaptcha_min_score =
            parseFloat(this.appData.gcp.recaptcha_min_score as any);
        }
        if (this.appData.gcp.recaptcha_min_score === undefined ||
           isNaN(this.appData.gcp.recaptcha_min_score) ||
           this.appData.gcp.recaptcha_min_score < 0 ||
           this.appData.gcp.recaptcha_min_score > 1) {
          alert('Recaptha Puntaje Mínimo debe ser un número entre 0.0 y 1.0');
          return;
        }
      }
    }

    if (!this.validateTwilioFields()) {
      Swal.fire({
        title: 'Todos los campos de Twilio son obligatorios.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if(this.appData.twilio){
      if(!this.appData.twilio.verify_sid && !this.appData.twilio.token && !this.appData.twilio.sid){
        delete this.appData.twilio;
        remove_missing_fields = true;
      }
    }

    const output = new AppsEditorOutput(this.appData, this.profileFields, { remove_missing_fields: remove_missing_fields });
    this.lock_redemption.ConfirmationClick$.next(true);
    this.onOK.emit(output);
    return false;
  }

  cancelClicked() {
    this.onCancel.emit();
    return false;
  }

  addProfileField() {
    const newField: AppsEditorProfileField = {
      id: '',
      title: '',
      pattern: '',
      format: '',
      enum: [],
      use_in_auth: false,
      public: false,
      validation_type: 'free',
      self_managed : false,
      self_managed_programs:[],
      autocomplete_url:'',
      required : false

    };
    this.profileFields.push(newField);
  }

  removeProfileField(field) {
    const index = this.profileFields.indexOf(field);
    if (index >= 0) {
      this.profileFields.splice(index, 1);
    }
  }

  addFieldEnumItem(event: MatChipInputEvent, field) {
    const value = event.value.trim();
    const input = event.input;

    if (value && input) {
      field.enum.push(value);
      input.value = '';
    }
  }

  addAudiences(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();

    if (value && input) {
      this.appData.auth_jwt_info.audiences.push(value);
      input.value = '';
    }
  }

  removeAudience(index: number) {
    this.appData.auth_jwt_info.audiences.splice(index, 1);
  }

  setMainGCPProjectId(main_project_id: string) {
    if (!this.appData.gcp) {
      this.appData.gcp = {
        main_project_id,
        recaptcha_auth: false,
        recaptcha_secret: '',
        recaptcha_enterprise: false,
        recaptcha_keys_ids: [],
        recaptcha_min_score: 0.5,
      };
    }
    this.appData.gcp.main_project_id = main_project_id;
  }

  jwtInfoFromMainGCPProject() {
    const id = this.appData
      && this.appData.gcp
      && this.appData.gcp.main_project_id;
    if (!id) {
      return;
    }
    this.appData.auth_jwt_info = {
      issuer: `https://securetoken.google.com/${id}`,
      audiences: [id],
      jwks_uri: 'https://www.googleapis.com/robot/v1/metadata/x509/securetoken@system.gserviceaccount.com',
    };
  }

  filtersAutoComplete(value: string, type: string) {
    console.log('filtersAutoComplete', value, type);

    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }

  async loadPrograms() {
    if (!this.appData.id) {
      return;
    }
    let ids = this.programIdsByAppMap.get(this.appData.id);
    if (ids) {
      this.programsOptions = ids;
      this.programInputControl.setValue('', { emitEvent: true });
      return;
    }
    this.programIdsByAppMap.set(this.appData.id, []);
    const programsList: ProgramsList =
      await this.programsApi.findAppIdPrograms(this.appData.id).toPromise();
    if (!programsList.data) {
      return;
    }
    ids = programsList.data.map((program) => program.id);
    this.programsOptions = ids.map((program) => program.split('/').slice(-1).pop());
    this.programIdsByAppMap.set(this.appData.id, ids);
    this.programInputControl.setValue('', { emitEvent: true });
  }

  addProgram(event: MatChipInputEvent,index?) {
    const input = event.input;
    const value = event.value.trim();

    if (value && input) {
      // Check input program is known
      if (this.programsOptions.indexOf(value) < 0) {
        return;
      }
      // Init property if non existent
      if (!this.profileFields[index].self_managed_programs) {
        this.profileFields[index].self_managed_programs = [];
      }
      // Check value is not repeated
      if (this.profileFields[index].self_managed_programs.indexOf(value) >= 0) {
        return;
      }
      // Add value

      this.profileFields[index].self_managed_programs.push(value);
      input.value = '';
    }
  }

  removeProgram(program: string,index_field, index_program) {
    if (index_program >= 0) {
      this.profileFields[index_field].self_managed_programs.splice(index_program, 1);
    }
  }

  validateTwilioFields(): boolean {
    const { verify_sid, token, sid } = this.appData.twilio;

    const isAtLeastOneFilled = !!verify_sid || !!token || !!sid;
    const areAllFilled = !!verify_sid && !!token && !!sid;

    // Si al menos uno está lleno, entonces todos deben estar llenos
    // Si todos están vacíos, está bien y pasa la validación
    return !isAtLeastOneFilled || areAllFilled;
  }
}
