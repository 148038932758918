import { PERMISSIONS } from '../../services/permissions/permissions.service';
/* eslint-disable max-len */
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { typeProductOptions, stateOptions, ivaOptions } from '../../models/preinvoice/preinvoice-settings-model';
import { preinvoice, preinvoiceList, statePreinvoiceOptions, statesPreinvoiceForSelect, preinvoiceParams } from '../../models/preinvoice/preinvoice-model';
import { OrdersCentralService } from '../../services/orders-central.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { orderCentral, orderCentralList, orderCentralParams, orderMasiveResponse, orderResult} from '../../models/order-central/order-central-model';
import { Countries } from '../../countries';
import { typeProductForSelect, statesForSelect } from '../../models/preinvoice/preinvoice-settings-model';
import {CompaniesService} from '../../users-api';
import {ProgramsService} from '../../users-api';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../app-common/confirm-dialog/confirm-dialog.component';

export class OrderCentralDataSource extends DataSource<orderCentral> {
  private dataSubject = new BehaviorSubject<orderCentral[]>([]);
  observer: Observer<orderCentral[]>;

  connect(): Observable<orderCentral[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {}

  refreshData(data: orderCentral[]) {
    this.dataSubject.next(data);
  }

  get data(): orderCentral[] {
    return this.dataSubject.getValue();
  }
}

@Component({
  selector: 'app-order-central-settings',
  templateUrl: './order-central.component.html',
  styleUrls: ['./order-central.component.scss']
})
export class OrderCentralComponent implements OnInit {
  PERMISSIONS = PERMISSIONS
  dataSource = new OrderCentralDataSource();
  displayedColumns = ['id'];
  pageSize = 100;
  loading = false;
  hoveredRow: any;
  pagerSteps = [100, 200, 500, 1000];
  prevCursor: string;
  nextCursor: string;
  firstCursor: string;
  requiredFields: boolean = false;
  resultsFound = true;
  allowedCompanyIds: string[];
  allowAllCompanyIds = false;
  foundNumber: number;

  companyIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  programIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idOptions: Observable<string[]>;
  currentCompanyId: string;
  currentProgramId: string;
  resultsNumberLimit = 100;
  data;

  statusPreinvoice: string | null = null;
  statusPreinvoiceOptions: {
    label: string;
    value: string;
  }[];

  numeroPreinvoice: string = '';
  numeroFactura: string = '';
  
  //Campos de los filtros
  delivery_globalid: string = '';
  country: string | null = null;
  instance_username: string | null = null;
  instance_user_iddoc: string | null = null;
  instance_user_names: string | null = null;
  instance_user_lastnames: string | null = null;
  sku: string | null = null;
  type_product: string | null = null;
  central_delivery_state: string | null = null;
  start_date: string | null = null;
  end_date: string | null = null;
  error: string | null = null;
  error_msg: string | null = null;
  
  //Opciones de los Selects
  typeProduct = typeProductForSelect;
  statesDelivery = statesForSelect;
  countries = Countries.COUNTRIES;

  //Resultados Operaciones
  dataResult: boolean = false;
  results: orderResult[] | any = [];
  
  constructor(
    private ordersCentralService: OrdersCentralService,
    private storageService: StorageService,
    private router: Router,
    private companiesService: CompaniesService,
    private programsApi: ProgramsService,
    private dialog: MatDialog
  ) {
    this.statusPreinvoiceOptions = [{
      label: 'Seleccionar',
      value: ''
    }];
    this.statusPreinvoiceOptions.push(...statesPreinvoiceForSelect);
  }

  async ngOnInit() {
    await this.initAllowedIds();
  }

  async getSearchOrders(companyId?, programId?, pedido?, pais?, documento?, username?, nombres?, apellido?, sku?, type_product?, central_delivery_state?, start_date?, end_date?, error?, error_msg?) {
    let customerId;
    let dataprogram;
    let codeprogram;
    if(companyId){
      customerId = await this.companiesService.retrieveCompany(companyId).toPromise();
      console.log(customerId.id_doc);
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
    }
    let fullProgram: string;
    let divProgram: string[];
    if(programId) {
      
      fullProgram = programId;
      divProgram = fullProgram.split('/')
      console.log(divProgram)
      const dataprogram = await this.programsApi.retrieveProgram(companyId, divProgram[3]).toPromise();
      codeprogram = dataprogram.erp.program_code;
      console.log(dataprogram.erp.program_code);
    }

    const query: orderCentralParams = {
      'customer_id': companyId ? customerId.id_doc : null,
      'program_id': programId ? codeprogram : null,
      'delivery_globalid': pedido ? pedido : null,
      'countryCode': pais ? pais : null,
      'instance_user_iddoc': documento ? documento : null,
      'instance_username': username ? username : null,
      'instance_user_names': nombres ? nombres : null,
      'instance_user_lastnames': apellido ? apellido : null,
      'sku': sku ? sku : null,
      'type_product': type_product ? type_product : null,
      'central_delivery_state': central_delivery_state ? central_delivery_state : null,
      'start_date': start_date ? start_date.toISOString() : null,
      'end_date': end_date ? end_date.toISOString() : null,
      'error': error ? (error=="0" ? 0 : 1) : null,
      'error_msg': error_msg ? error_msg : null,
    };
    console.log(query);
    await this.ordersCentralService.listOrderCentral(query).subscribe((res: orderCentralList) => {
      console.log(res.data);
      this.data = res.data;
      this.dataSource.refreshData(this.data);
      this.resultsFound = this.data.length > 0 ? true : false;
    });
  }

  onCompanySelected(companyId: string) {
    this.companyIdFilter$.next(companyId);
    this.currentCompanyId = companyId;
    this.currentProgramId = null;
    this.getSearchOrders(companyId, this.currentProgramId);
  }

  onProgramSelected(programId: string) {
    this.programIdFilter$.next(programId);
    this.currentProgramId = programId;
    this.getSearchOrders(this.currentCompanyId, programId);
  }

  onEdit(item, state){
    this.router.navigate(['pages', 'order', 'edit', item.preinvoice_id, state]);
  }

  onView(item){
    this.router.navigate(['pages', 'orderCentral', 'view', item.delivery_globalid]);
  }

  resetCompany() {
    this.currentCompanyId = null;
    this.getSearchOrders(this.currentCompanyId, this.currentProgramId);
  }

  resetProgram() {
    this.currentProgramId = null;
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedCompanyIds = [];
    this.allowAllCompanyIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedCompanyIds = [];
      this.allowAllCompanyIds = true;
      return;
    }
  }

  typeProductoVisible(items){
  const product = items.map(item =>
    typeProductOptions[item]);
  const listproduct = product.join(', ');
  return listproduct;
  }

  stateDeliveryVisible(items){
    const state = items.map(item =>
      stateOptions[item]);
    const liststate = state.join(', ');
    return liststate;
  }

  errorVisible(item){
    return item == 1 ? 'Si' : 'No';
  }

  stateVisible(item){
    return stateOptions[item];
  }

  typeVisible(item){
    return typeProductOptions[item];
  }

  searchDelivery() {
    console.log('Buscando:', this.currentCompanyId, this.currentProgramId, this.delivery_globalid, this.country, this.instance_user_iddoc, this.instance_username, this.instance_user_names, this.instance_user_lastnames, this.sku, this.type_product, this.central_delivery_state, this.start_date, this.end_date, this.error, this.error_msg);

    this.getSearchOrders(this.currentCompanyId, this.currentProgramId, this.delivery_globalid, this.country, this.instance_user_iddoc, this.instance_username, this.instance_user_names, this.instance_user_lastnames, this.sku, this.type_product, this.central_delivery_state, this.start_date, this.end_date, this.error, this.error_msg);
   }

   toggleSelectAll(event: any): void {
    const checked = event.checked;
    this.dataSource.data.forEach((row: orderCentral) => row.selected = checked);
  }
    
  isIndeterminate(): boolean {
    const anySelected = this.dataSource.data.some((row: orderCentral) => row.selected);
    return anySelected && !this.isAllSelected();
  }
  
  async procesarSeleccionados(accion: string) {
    const seleccionados = this.dataSource.data
      .filter((row: orderCentral) => row.selected)
      .map((row: orderCentral) => row.delivery_globalid);
  
    console.log('Seleccionados:', seleccionados);
    console.log('Accion:', accion);
    let dataOperacion: orderMasiveResponse | any = {};
    switch (accion) {
      case 'notificar':
        await this.ordersCentralService.postNotificationState({orders: seleccionados}).subscribe((dataOperacion: orderMasiveResponse) => {
          console.log(dataOperacion);
          if (dataOperacion.updated === true) {
            this.dataResult = true;
            this.results = dataOperacion.results;
            console.log(dataOperacion.results);
            this.searchDelivery();
          }
        });
        break;
      case 'cancelar':
        dataOperacion = await this.ordersCentralService.putCancelDeliveries({orders: seleccionados}).subscribe((dataOperacion: orderMasiveResponse) => {
          console.log(dataOperacion);
          if (dataOperacion.updated === true) {
            this.dataResult = true;
            this.results = dataOperacion.results;
            console.log(dataOperacion.results);
            this.searchDelivery();
          }
        });
        break;
      case 'limpiar':
        dataOperacion = await this.ordersCentralService.putCleanDeliveries({orders: seleccionados}).subscribe((dataOperacion: orderMasiveResponse) => {
          console.log(dataOperacion);
          if (dataOperacion.updated === true) {
            this.dataResult = true;
            this.results = dataOperacion.results;
            console.log(dataOperacion.results);
            this.searchDelivery();
          }
        });
        break;
      case 'marcar':
        dataOperacion = await this.ordersCentralService.putMarkThirdDeliveries({orders: seleccionados}).subscribe((dataOperacion: orderMasiveResponse) => {
          console.log(dataOperacion);
          if (dataOperacion.updated === true) {
            this.dataResult = true;
            this.results = dataOperacion.results;
            console.log(dataOperacion.results);
            this.searchDelivery();
          }
        });
        break;
      case 'estadoERP':
        dataOperacion = await this.ordersCentralService.putSetErpDeliveries({orders: seleccionados}).subscribe((dataOperacion: orderMasiveResponse) => {
          console.log(dataOperacion);
          if (dataOperacion.updated === true) {
            this.dataResult = true;
            this.results = dataOperacion.results;
            console.log(dataOperacion.results);
            this.searchDelivery();
          }
        });
        break;
      case 'reintentarErp':
        dataOperacion = await this.ordersCentralService.putRetryErpDeliveries({orders: seleccionados}).subscribe((dataOperacion: orderMasiveResponse) => {
          console.log(dataOperacion);
          if (dataOperacion.updated === true) {
            this.dataResult = true;
            this.results = dataOperacion.results;
            console.log(dataOperacion.results);
            this.searchDelivery();
          }
        });
        break;
    }
  }
    
  isSomeSelected(): boolean {
    return this.dataSource.data.some(item => item.selected) && !this.isAllSelected();
  }
  
  toggleAll(event: any): void {
    const isChecked = event.checked;
    this.dataSource.data.forEach(item => item.selected = isChecked);
  }

  onSelectRow(item: orderCentral, event: any): void {
    item.selected = event.checked;
  
    const anySelected = this.dataSource.data.some(i => i.selected);
    const allSelected = this.dataSource.data.every(i => i.selected);
  
    // Ejemplo si necesitas manejar estado global
    console.log('Al menos uno seleccionado:', anySelected);
    console.log('Todos seleccionados:', allSelected);
  }
  toggleAllRows() {
    const allSelected = this.isAllSelected();
    this.dataSource.data.forEach(item => (item.selected = !allSelected));
  }
  
  isAllSelected(): boolean {
    return this.dataSource.data.length > 0 && this.dataSource.data.every(item => item.selected);
  }

  hasSelectedRows(): boolean {
    return this.dataSource.data.some(item => item.selected);
  }

  abrirDialogoConfirmacion(accion: string) {
    let mensajes = {
      notificar: { titulo: 'Confirmación', mensaje: '¿Deseas notificar el estado?', confirm: 'Sí, notificar' },
      cancelar: { titulo: 'Confirmación', mensaje: '¿Deseas cancelar el pedido?', confirm: 'Sí, cancelar' },
      limpiar: { titulo: 'Confirmación', mensaje: '¿Deseas limpiar registros del importador del ERP?', confirm: 'Sí, limpiar' },
      marcar: { titulo: 'Confirmación', mensaje: '¿Deseas marcar el pedido como Tercero?', confirm: 'Sí, marcar' },
      estadoERP: { titulo: 'Confirmación', mensaje: '¿Deseas cambiar el estado a Creado en el ERP?', confirm: 'Sí, cambiar' },
      reintentarErp: { titulo: 'Confirmación', mensaje: '¿Deseas reintentar importar al ERP?', confirm: 'Sí, reintentar' },
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: mensajes[accion].titulo,
        message: mensajes[accion].mensaje,
        confirmText: mensajes[accion].confirm,
        cancelText: 'Cancelar'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.procesarSeleccionados(accion);
      }
    });
  }
}

