/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information. 
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ManagedEntity } from './managedEntity';
import { AppUserProfileInput } from './appUserProfileInput';
import { AppAllOf } from './appAllOf';


export interface AppUserProfileOutput { 
    data: { [key: string]: Array<string>; };
    created_at: Date;
    created_by: string;
    updated_at?: Date;
    updated_by?: string;
    id: string;
}

