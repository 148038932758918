/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information. 
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BaseList } from './baseList';
import { ProgramsListAllOf } from './programsListAllOf';
import { ProgramFull } from './programFull';


/**
 * A list of programs.
 */
export interface ProgramsList { 
    next_page_url?: string;
    next_page_cursor?: string;
    prev_page_url?: string;
    prev_page_cursor?: string;
    first_page_cursor?: string;
    data: Array<ProgramFull>;
}

