// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-toolbar .spacer {
  flex: 1 1 auto;
}

mat-sidenav-content {
  background-color: #eee;
}

button.picture-button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/container/container.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/container/container.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;ACAJ;;ADIA;EACE,sBAAA;ACDF;;ADIA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;ACDF","sourcesContent":["mat-toolbar {\n  .spacer {\n    flex: 1 1 auto;\n  }\n}\n\nmat-sidenav-content {\n  background-color: #eee;\n}\n\nbutton.picture-button img {\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n}\n","mat-toolbar .spacer {\n  flex: 1 1 auto;\n}\n\nmat-sidenav-content {\n  background-color: #eee;\n}\n\nbutton.picture-button img {\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
