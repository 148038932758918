import { PERMISSIONS } from '../../services/permissions/permissions.service';
/* eslint-disable max-len */
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { typeProductOptions, stateOptions, ivaOptions } from '../../models/preinvoice/preinvoice-settings-model';
import { preinvoice, preinvoiceList, statePreinvoiceOptions, statesPreinvoiceForSelect, preinvoiceParams } from '../../models/preinvoice/preinvoice-model';
import { OrdersCentralService } from '../../services/orders-central.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';

export class PreinvoiceDataSource extends DataSource<preinvoice> {
  observer: Observer<preinvoice[]>;

  refreshData(data: preinvoice[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<preinvoice[]> {
    return Observable.create((observer: Observer<preinvoice[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-preinvoice-settings',
  templateUrl: './preinvoice.component.html',
  styleUrls: ['./preinvoice.component.scss']
})
export class PreinvoiceComponent implements OnInit {
  PERMISSIONS = PERMISSIONS
  dataSource = new PreinvoiceDataSource();
  displayedColumns = ['id'];
  pageSize = 100;
  loading = false;
  hoveredRow: any;
  pagerSteps = [100, 200, 500, 1000];
  prevCursor: string;
  nextCursor: string;
  firstCursor: string;
  requiredFields: boolean = false;
  resultsFound = true;
  allowedCompanyIds: string[];
  allowAllCompanyIds = false;
  foundNumber: number;

  companyIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  programIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idOptions: Observable<string[]>;
  currentCompanyId: string;
  currentProgramId: string;
  resultsNumberLimit = 100;
  data;

  statusPreinvoice: string | null = null;
  statusPreinvoiceOptions: {
    label: string;
    value: string;
  }[];

  numeroPreinvoice: string = '';
  numeroFactura: string = '';
  
  
  constructor(
    private ordersCentralService: OrdersCentralService,
    private storageService: StorageService,
    private router: Router,
  ) {
    this.statusPreinvoiceOptions = [{
      label: 'Seleccionar',
      value: ''
    }];
    this.statusPreinvoiceOptions.push(...statesPreinvoiceForSelect);
  }

  async ngOnInit() {
    await this.initAllowedIds();
  }

  async getPreinvoice(companyId?, programId?, status?, invoiceNumber?, preinvoiceNumber?) {
    if(companyId){
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
      this.data = [];
      this.dataSource.refreshData(this.data);
      return;
    }
    let fullProgram: string;
    let divProgram: string[];
    if(programId) {
      console.log(programId);
      fullProgram = programId;
      divProgram = fullProgram.split('/')
      console.log(divProgram)
    }
    const query: preinvoiceParams = {
      'customer_id': companyId ? companyId : null,
      'program_id': programId ? divProgram[3] : null,
      'nro_preinvoice': preinvoiceNumber ? preinvoiceNumber : null,
      'numero_de_factura': invoiceNumber ? invoiceNumber : null,
      'delivery_invoice_state': status ? status : null,
    };
    console.log(query);
    await this.ordersCentralService.listPreinvoice(query).subscribe((res: preinvoiceList) => {
      console.log(res.data);
      this.data = res.data;
      this.dataSource.refreshData(this.data);
      this.resultsFound = this.data.length > 0 ? true : false;
    });
  }

  onCompanySelected(companyId: string) {
    this.companyIdFilter$.next(companyId);
    this.currentCompanyId = companyId;
    this.currentProgramId = null;
    this.getPreinvoice(companyId, this.currentProgramId);
  }

  onProgramSelected(programId: string) {
    this.programIdFilter$.next(programId);
    this.currentProgramId = programId;
    this.getPreinvoice(this.currentCompanyId, programId);
  }

  onCreate(){
    this.router.navigate(['pages', 'preinvoice', 'create']);
  }

  onEdit(item, state){
    this.router.navigate(['pages', 'preinvoice', 'edit', item.preinvoice_id, state]);
  }

  onView(item){
    this.router.navigate(['pages', 'preinvoice', 'view', item.preinvoice_id]);
  }

  resetCompany() {
    this.currentCompanyId = null;
    this.getPreinvoice(this.currentCompanyId, this.currentProgramId);
  }

  resetProgram() {
    this.currentProgramId = null;
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedCompanyIds = [];
    this.allowAllCompanyIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedCompanyIds = [];
      this.allowAllCompanyIds = true;
      return;
    }
  }

  typeProductoVisible(items){
  const product = items.map(item =>
    typeProductOptions[item]);
  const listproduct = product.join(', ');
  return listproduct;
  }

  stateDeliveryVisible(items){
    const state = items.map(item =>
      stateOptions[item]);
    const liststate = state.join(', ');
    return liststate;
  }

  ivasVisible(items){
    const ivas = items.map(item =>
      ivaOptions[item]);
    const listivas = ivas.join(', ');
    return listivas;
  }

  statePreinvoiceVisible(item){
    return statePreinvoiceOptions[item];
  }

  typePreinvoiceVisible(item){
    return item === 'automatic' ? 'Automatica' : 'Manual';
  }

  searchStatus() {
    if (this.statusPreinvoice) {
      console.log('Buscando value:', this.statusPreinvoice);
      const selectedLabel = this.statusPreinvoiceOptions.find(statusPreinvoiceOptions => statusPreinvoiceOptions.value === this.statusPreinvoice)?.label;
      console.log('Buscando por:', selectedLabel || this.statusPreinvoice);
      this.getPreinvoice(this.currentCompanyId, this.currentProgramId, this.statusPreinvoice, this.numeroFactura, this.numeroPreinvoice);
      // Aquí puedes realizar la acción de búsqueda
    } else {
      console.warn('Por favor, selecciona una opción antes de buscar.');
    }
  }

  searchPreinvoice() {
    if (this.numeroPreinvoice.trim()) {
      console.log('Buscando:', this.numeroPreinvoice);
      this.getPreinvoice(this.currentCompanyId, this.currentProgramId, this.statusPreinvoice, this.numeroFactura, this.numeroPreinvoice);
      // Aquí puedes realizar la acción de búsqueda, como llamar a un servicio o API.
    } else {
      console.warn('El campo de búsqueda está vacío.');
    }
  }

  searchInvoice() {
    if (this.numeroFactura.trim()) {
      console.log('Buscando:', this.numeroFactura);
      // Aquí puedes realizar la acción de búsqueda, como llamar a un servicio o API.
      this.getPreinvoice(this.currentCompanyId, this.currentProgramId, this.statusPreinvoice, this.numeroFactura, this.numeroPreinvoice);
    } else {
      console.warn('El campo de búsqueda está vacío.');
    }
  }

}

