// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.mat-chip-input {
  width: 100%;
}

.icon-help {
  font-size: 18px;
  margin: 0 0.5rem;
}

.name-id,
#point-values-header,
#grantors-header,
.point-values,
.point-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name-id mat-form-field,
#point-values-header mat-form-field,
#grantors-header mat-form-field,
.point-values mat-form-field,
.point-name mat-form-field {
  width: 45%;
}

#main-actions {
  display: flex;
  flex-direction: row-reverse;
}
#main-actions button {
  margin: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/couriers/couriers-form/couriers-form.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/couriers/couriers-form/couriers-form.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;ACCJ;;ADEA;EACI,eAAA;EACA,gBAAA;ACCJ;;ADEA;;;;;EAKE,aAAA;EACA,8BAAA;EACA,mBAAA;ACCF;ADAE;;;;;EACE,UAAA;ACMJ;;ADFA;EACE,aAAA;EACA,2BAAA;ACKF;ADHE;EACE,WAAA;ACKJ","sourcesContent":["input.mat-chip-input {\n    width: 100%;\n}\n\n.icon-help{\n    font-size: 18px;\n    margin: 0 0.5rem;\n}\n\n.name-id,\n#point-values-header,\n#grantors-header,\n.point-values,\n.point-name {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  mat-form-field {\n    width: 45%;\n  }\n}\n\n#main-actions {\n  display: flex;\n  flex-direction: row-reverse;\n\n  button {\n    margin: 8px;\n  }\n}","input.mat-chip-input {\n  width: 100%;\n}\n\n.icon-help {\n  font-size: 18px;\n  margin: 0 0.5rem;\n}\n\n.name-id,\n#point-values-header,\n#grantors-header,\n.point-values,\n.point-name {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.name-id mat-form-field,\n#point-values-header mat-form-field,\n#grantors-header mat-form-field,\n.point-values mat-form-field,\n.point-name mat-form-field {\n  width: 45%;\n}\n\n#main-actions {\n  display: flex;\n  flex-direction: row-reverse;\n}\n#main-actions button {\n  margin: 8px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
