import { PERMISSIONS } from '../../../services/permissions/permissions.service';
/* eslint-disable max-len */
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { OPTIONS_TYPES_GENERALES, OPTIONS_TYPESMONT} from '../../../models/bags/bags-model';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import {switchMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {preinvoiceDetail, preinvoiceListComponents, preinvoiceComponent, statePreinvoiceOptions} from '../../../models/preinvoice/preinvoice-model';
import {stateOptions, ivaOptions} from '../../../models/preinvoice/preinvoice-settings-model';
import { OrdersCentralService } from '../../../services/orders-central.service';

export class PreinvoiceDetailDataSource extends DataSource<preinvoiceComponent> {
  observer: Observer<preinvoiceComponent[]>;

  refreshData(data: preinvoiceComponent[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<preinvoiceComponent[]> {
    return Observable.create((observer: Observer<preinvoiceComponent[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-preinvoice-detail',
  templateUrl: './preinvoice-detail.component.html',
  styleUrls: ['./preinvoice-detail.component.scss']
})
export class PreinvoiceDetailComponent implements OnInit {
[x: string]: any;
  PERMISSIONS = PERMISSIONS
  dataSource = new PreinvoiceDetailDataSource();
  displayedColumns = ['delivery_globalid','payment_component_id','sku','product_name','product_brand', 'shipping_address', 'points_price','price','vat_value','central_delivery_state'];
  loading = false;
  hoveredRow: any;
  allowedCompanyIds: string[];
  allowAllCompanyIds = false;
  data;
  preinvoiceId: string;
  preinvoiceData: preinvoiceDetail | null ;

  constructor(
    private ordersCentralService: OrdersCentralService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    await this.initAllowedIds();

    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      this.preinvoiceId = params.get('id');
      return this.ordersCentralService.getPreinvoiceIdComponents(this.preinvoiceId);
    })).subscribe((res: preinvoiceListComponents) => {
      this.data = res.data;
      this.dataSource = this.data;
      this.loading = false;
      console.log(this.data);
    }, error  => {
      console.error('Reading preinvoice', error);
      this.snackBar.open('Error reading preinvoice: ' + error);
    });
    this.getPreinvoiceInfo(this.preinvoiceId);    
  }

  async getPreinvoiceInfo(id) {
    return this.ordersCentralService.getPreinvoiceId(id).subscribe(async (ans: preinvoiceDetail) => {
      this.preinvoiceData = ans;
      console.log(this.preinvoiceData);
    });
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }


  initAllowedIds() {
    // Do not allow any app by default
    this.allowedCompanyIds = [];
    this.allowAllCompanyIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedCompanyIds = null;
      this.allowAllCompanyIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedCompanyIds = profile.authorized_apps || [];
  }

  typeTransaction(item){
    return OPTIONS_TYPES_GENERALES[item];
  }

  type(item){
    return OPTIONS_TYPESMONT[item];
  }

  onEdit(item, state){
    this.router.navigate(['pages', 'preinvoice', 'edit', item.preinvoice_id, state]);
  }

  stateDeliveryVisible(item){
    return stateOptions[item];
  }

  statePreinvoiceVisible(item){
    return statePreinvoiceOptions[item];
  }

  ivasVisible(items){
    const ivas = items.map(item =>
        ivaOptions[item]);
    const listivas = ivas.join(', ');
    return listivas;
  }

  extractFileLabel(fileName: string): string {
    const parts = fileName.split('/');
    return parts.length > 1 ? parts.slice(1).join(' ') : fileName;
  }

  getlinkfile(filename) {
    const url = "https://storage.cloud.google.com/preinvoice-files/"
    return url+filename;
  }

}

