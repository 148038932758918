// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: grid;
}`, "",{"version":3,"sources":["webpack://./src/app/app-common/program-selector/program-selector.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/app-common/program-selector/program-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;ACCF","sourcesContent":[":host {\n  display: grid;\n}\n",":host {\n  display: grid;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
