export interface preinvoiceParams {
    nro_preinvoice?: string | null;
    numero_de_factura?: string | null;
    customer_id: string | null;
    program_id?: string | null;
    delivery_invoice_state?: string | null;
}

export interface preinvoice {
    preinvoice_id: string;
    preinvoice_create_ts: string;
    number_invoice: string;
    numero_de_factura: string;
    customer_nit: string;
    program_code: string;
    ivas: [];
    type: string;
    delivery_invoice_state: string;
    fecha_de_radicacion: string;
    fecha_factura: string;
    observaciones: string;
    customer_id: string;
    program_id: string;
}

export interface preinvoiceList {
    data: preinvoice[];
}

export const statePreinvoiceOptions = {
    'pending': 'Pendiente de aprobación',
    'invoiced': 'Facturado',
    'approved': 'Aprobada',
    'filed': 'Radicada',
    'rejected': 'Rechazada',
    'external': 'Pre-Factura externa',
    'cancelled': 'Anulada',    
};

export const statesPreinvoiceForSelect = Object.entries(statePreinvoiceOptions).map(([k, v]) => ({
    label: v,
    value: k,
  }));

export const OPTIONS_TYPESPREINVOICE = {
    'automatic': 'Automatica',
    'manual': 'Manual',
};

export interface preinvoiceManual {
    program_id: string;
    customer_id: string;
    type_redemption: string;
    type_preinvoice: string;
    type_product: [];
    deliveries: string;
    estimate: number;
    ivas: [];
    date_finish: string;
}

export const OPTIONS_TYPESPREINVOICES_MANUAL = {
    'redimido': 'Redimido',
    'entregado': 'Entregado'
};

export const OPTIONS_TYPESREDEMPTION_MANUAL = {
    'todos': 'Todos',
    'normal': 'Normal',
    'pointz': 'Pointz',
};


export const typeStateForSelect = Object.entries(OPTIONS_TYPESPREINVOICES_MANUAL).map(([k, v]) => ({
    label: v,
    value: k,
}));

export const typePointsForSelect = Object.entries(OPTIONS_TYPESREDEMPTION_MANUAL).map(([k, v]) => ({
    label: v,
    value: k,
}));

export interface dataCalculatePreinvoice {
    nro_deliveries: number;
    nro_components: number;
    customer: string;
    program: string;
    price_before: number;
    total_vat: number;
    total_price: number;
    total_points: number;
    vat_preinvoice?: number;
    price_before_notvat?: number;
    deliveries: string[];
    components: string[];
}

export interface preinvoiceDetail {
    preinvoice_id: string;
    nro_deliveries: number;
    nro_components: number;
    preinvoice_create_ts: string;
    preinvoice_modify_ts: string;
    number_invoice: string;
    numero_de_factura: string;
    customer_nit: string;
    program_code: string;
    ivas: [];
    delivery_invoice_state: string;
    fecha_de_radicacion: string;
    fecha_factura: string;
    observaciones: string;
    adjuntos: [],
    total_vat: number;
    total_points: number;
    total_prefactura: number;
    price_before: number;
    price_before_notvat: number;
}

export interface preinvoiceComponent {
    payment_component_id: string;
    delivery_globalid: string;
    sku: string;
    product_name: string;
    product_brand: string;
    points_price: number;
    price: number;
    vat_value: number;
    central_delivery_state: string;
    shipping_address: string;
}

export interface preinvoiceListComponents {
    data: preinvoiceComponent[];
}

export interface changeStatePreinvoice {
    idpreinvoice: string;
    state: string;
    invoice_number?: string;
    invoice_date?: string;
    filing_date?: string;
    observaciones?: string;
}


export const optionStatePreinvoice = {
    'pending': {
        'approved': 'Aprobada',
        'external': 'Pre-Factura externa',
        'rejected': 'Rechazada',
      option: 'pending',
      state: ['approved', 'external', 'rejected'],
    },
    'approved': {
      option: 'approved',
      state: ['invoiced', 'cancelled'],
    },
    'invoiced': {
      state: ['filed', 'cancelled'],
    },
    'rejected': {
      option: 'rejected',
      state: [],
    },
    'filed': {
      option: 'filed',
      state: ['cancelled'],
    },
    'cancelled': {
      option: 'cancelled',
      state: [],
    },
};