import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MainSidenavService } from '../../../../main-sidenav.service';
import {
  ProgramsService,
  ProgramBase,
} from '../../../../users-api';

import { CompaniesUtilService } from '../../../../services/companies-util.service';

@Component({
  selector: 'app-programs-add',
  templateUrl: './programs-add.component.html',
  styleUrls: ['./programs-add.component.scss'],
  providers: [ ProgramsService ]
})
export class ProgramsAddComponent implements OnInit {
  program: ProgramBase;
  companyId: string;
  loading= true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private programsApi: ProgramsService,
    public mainSidenavService: MainSidenavService,
    private snackBar: MatSnackBar,
    private companiesUtilService: CompaniesUtilService,
  ) { }

  ngOnInit() {
    //this.idDoc = this.companiesUtilService.getCompanyIdDoc();
    this.program = {
      app_id: '',
      company_id: '',
      program_id: '',
      name: '',
      country: '',
      points_account_type: '',
      budget: {
        enabled: false,
        value: {
          amount: 0,
          currency_code: '',
        },
        start_datetime: new Date(),
      },
      erp: {
        program_code: null,
        document_type: '',
        consecutive_type: '',
        wharehouse: '',
        currency: '',
        currency_invoice: '',
        company: this.companiesUtilService.getCompanyIdDoc()


      },
      disable_exchanges: false,
      nequi: {
        enabled: false,
        transfer_cost_cents: 0,
        transfer_tax_percent: 0,
        min_transfer_cents: 0,
        max_transfer_cents: 0,
        max_n_transfers: 0,
      },
      menu: [],
      tabs: []
    };
    this.loading = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.loading = false;
      this.companyId = params.get('companyId');
      this.program.company_id = this.companyId;
    }, error  => {
      this.loading = false;
      console.error('Getting company id from path', error);
    });
  }

  onEditorOK(program: ProgramBase) {
    this.loading = true;
    program.company_id = this.companyId;
    this.programsApi.createProgram(this.companyId, program).subscribe(() => {
      this.loading = false;
      this.router.navigate(['pages', 'companies', this.companyId, 'programs']);
    }, error => {
      this.loading = false;
      console.error('Error in createProgram', error);
      try {
        const data = error.error;
        this.snackBar.open(data.message, 'OK');
      } catch (ex) {
        this.snackBar.open('Ocurrió un error', 'OK');
      }
    });
  }

  onEditorCancel() {
    this.router.navigate(['pages', 'companies', this.companyId, 'programs']);
  }
}
