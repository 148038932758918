// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#programs-tab-content mat-form-field,
#roles-tab-content mat-form-field {
  width: 100%;
}
#programs-tab-content mat-form-field input,
#roles-tab-content mat-form-field input {
  width: 100%;
}

mat-form-field {
  width: 60%;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/app-users/app-users-editor/app-users-editor.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/app-users/app-users-editor/app-users-editor.component.scss"],"names":[],"mappings":"AAEE;;EACE,WAAA;ACAJ;ADEI;;EACE,WAAA;ACCN;;ADIA;EACE,UAAA;EACA,cAAA;ACDF","sourcesContent":["#programs-tab-content,\n#roles-tab-content {\n  mat-form-field {\n    width: 100%;\n\n    input {\n      width: 100%;\n    }\n  }\n}\n\nmat-form-field {\n  width: 60%;\n  margin: 0 auto;\n}\n","#programs-tab-content mat-form-field,\n#roles-tab-content mat-form-field {\n  width: 100%;\n}\n#programs-tab-content mat-form-field input,\n#roles-tab-content mat-form-field input {\n  width: 100%;\n}\n\nmat-form-field {\n  width: 60%;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
