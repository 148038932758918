// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  width: 100%;
  margin: 0 auto;
}

.btn-edit {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.header mat-form-field {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/allies-list/allies-list.component.scss","webpack://./../../P%C3%BAblico/users-frontend-ng/src/app/pages/pointz/allies-list/allies-list.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;ACCF;;ADEA;EACE,eAAA;ACCF;;ADEA;EACE,aAAA;EACA,2BAAA;EACA,uBAAA;ACCF;ADCE;EACE,UAAA;ACCJ","sourcesContent":[".loader {\n  width: 100%;\n  margin: 0 auto;\n}\n\n.btn-edit {\n  cursor: pointer;\n}\n\n.header {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n\n  mat-form-field {\n    width: 50%;\n  }\n}\n",".loader {\n  width: 100%;\n  margin: 0 auto;\n}\n\n.btn-edit {\n  cursor: pointer;\n}\n\n.header {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n.header mat-form-field {\n  width: 50%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
