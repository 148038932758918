/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { App } from "./app";
import { ManagedEntity } from "./managedEntity";
import { OpenIDInfo } from "./openIDInfo";

export interface AppOutput {
  name?: string;
  type?: string;
  openid?: OpenIDInfo;
  id: string;
  auth_jwt_info: {
    issuer: "";
    audiences: [];
    jwks_uri: "";
  };
  created_at: Date;
  created_by: string;
  updated_at?: Date;
  updated_by?: string;
}
